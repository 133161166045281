
// Create and Update Form
import {Button, DatePicker, Drawer, Form, Input, notification, Select} from "antd";
import React, {Component} from "react";
import {FormType} from "~/src/utils/form";
import ShopApi from "~/src/api-client/api/ManagementShopApi";
import moment from "moment";

@Form.create()
class CUForm extends Component {
    static defaultProps = {
        formType: FormType.create,
        data: {

        }
    };

    state = {
        haveShopper: false,
    }

    create = (data) => {
        return new ShopApi().managementShopCreateOrder({
            items: [{
                itemId: parseInt(data.itemId),
                quantity: parseInt(data.quantity),
                revisedQuantity: parseInt(data.revisedQuantity),
            }],
            date: data.date,
            status: data.status,
            userId: data.userId,
        }).then(() => {
            if (this.props.onSuccess) {
                this.props.onSuccess()
            }
            notification.success({message: `The order was successfully created`})
        }).catch((err) => {
            notification.warning({message: `Cannot create ${data.name}`, description: `${err}`, duration: 0});
        });
    };

    modify = (data) => {
        return new ShopApi().managementShopUpdateOrder(this.props.data.id, {
            items: [{
                itemId: parseInt(data.itemId),
                quantity: parseInt(data.quantity),
                revisedQuantity: parseInt(data.revisedQuantity),
            }],
            date: data.date,
            status: data.status,
            userId: data.userId,
        }).then(() => {
            if (this.props.onSuccess) {
                this.props.onSuccess()
            }
            notification.success({message: `The order was successfully modified`})
        }).catch((err) => {
            notification.warning({
                message: `Cannot modify  ${this.props.data.name}`,
                description: `${err}`,
                duration: 0
            });
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (err) {
                return;
            }

            console.log('Received values of form: ', values);
            this[this.props.formType](values)
        });
    }

    componentDidMount() {
        // Class list
    }

    render() {
        // Order Date
        // Class
        // Item
        // Qty
        // Revised Qty
        // Status
        const {getFieldDecorator} = this.props.form;
        const {user, items, status, revisedQuantity, date} = this.props.data;
        const {orgUsers, itemList} = this.props;

        return <Drawer
            title={this.props.title}
            width={720}
            onClose={this.props.onClose}
            visible={this.props.isOpen}
        >
            <Form layout="vertical" onSubmit={this.handleSubmit}>
                <Form.Item label="Order Date" hasFeedback colon={false}>
                    {getFieldDecorator('date', {rules: [{required: true}], initialValue: date ? moment(date): null})(
                        <DatePicker />
                    )}
                </Form.Item>
                <Form.Item label="Class / School" hasFeedback colon={false}>
                    {getFieldDecorator('userId', {rules: [{required: true}], initialValue: user ? user.id: null})(
                        <Select>
                            {
                                orgUsers.map((v, i) => {
                                    return <Select.Option key={i} value={v.id}>{v.name}</Select.Option>
                                })
                            }
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label="Item" hasFeedback colon={false}>
                    {getFieldDecorator('itemId', {rules: [{required: true}], initialValue: items ? items[0].id: null})(
                        <Select>
                            {
                                itemList.map((v, i) => {
                                    return <Select.Option key={i} value={v.id}>{v.name}</Select.Option>
                                })
                            }
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label="Qty" hasFeedback colon={false}>
                    {getFieldDecorator('quantity', {rules: [{required: true}], initialValue: items ? items[0].quantity: ''})(
                        <Input type="number"/>
                    )}
                </Form.Item>
                <Form.Item label="Revised Qty" hasFeedback colon={false}>
                    {getFieldDecorator('revisedQuantity', {rules: [{required: false}], initialValue: items ? items[0].revisedQuantity: ''})(
                        <Input type="number"/>
                    )}
                </Form.Item>
                <Form.Item label="Status" hasFeedback colon={false}>
                    {getFieldDecorator('status', {rules: [{required: true}], initialValue: status ? status: 'confirmed'})(
                        <Select>
                            <Select.Option value={'pending'}>Pending</Select.Option>
                            <Select.Option value={'canceled'}>Canceled</Select.Option>
                            <Select.Option value={'confirmed'}>Confirmed</Select.Option>
                        </Select>
                    )}
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">Submit</Button>
                </Form.Item>
            </Form>
        </Drawer>
    }
}

export default CUForm;