/**
 * Big Waster API
 * This is a Big Waster API.
 *
 * OpenAPI spec version: 1.0
 * Contact: terry.wong@futureimpactlab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CompetitionCreateCompetitionParam from '../model/CompetitionCreateCompetitionParam';
import CompetitionOutputCmsCompetition from '../model/CompetitionOutputCmsCompetition';
import CompetitionOutputCmsCompetitions from '../model/CompetitionOutputCmsCompetitions';
import CompetitionUpdateCompetitionParam from '../model/CompetitionUpdateCompetitionParam';
import SubmissionCreateSubmissionParam from '../model/SubmissionCreateSubmissionParam';
import SubmissionOutputCmsSubmission from '../model/SubmissionOutputCmsSubmission';
import SubmissionOutputCmsSubmissions from '../model/SubmissionOutputCmsSubmissions';
import SubmissionOutputUploadSubmissionFile from '../model/SubmissionOutputUploadSubmissionFile';
import SubmissionUpdateSubmissionParam from '../model/SubmissionUpdateSubmissionParam';
import UtilHttpErrorBody from '../model/UtilHttpErrorBody';
import UtilHttpOkBody from '../model/UtilHttpOkBody';
import UtilHttpRowAffBody from '../model/UtilHttpRowAffBody';

/**
* ManagementCompetition service.
* @module api/ManagementCompetitionApi
* @version 1.0
*/
export default class ManagementCompetitionApi {

    /**
    * Constructs a new ManagementCompetitionApi. 
    * @alias module:api/ManagementCompetitionApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Get specific competition
     * @param {Number} competitionId competition id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CompetitionOutputCmsCompetition} and HTTP response
     */
    managementCompetitionWithHttpInfo(competitionId) {
      let postBody = null;

      // verify the required parameter 'competitionId' is set
      if (competitionId === undefined || competitionId === null) {
        throw new Error("Missing the required parameter 'competitionId' when calling managementCompetition");
      }


      let pathParams = {
        'competitionId': competitionId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = CompetitionOutputCmsCompetition;

      return this.apiClient.callApi(
        '/management/competition/{competitionId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get specific competition
     * @param {Number} competitionId competition id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CompetitionOutputCmsCompetition}
     */
    managementCompetition(competitionId) {
      return this.managementCompetitionWithHttpInfo(competitionId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create competition&#39;s submission
     * @param {Number} competitionId competition id
     * @param {module:model/SubmissionCreateSubmissionParam} submissionCreateSubmissionParam Create submission parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UtilHttpOkBody} and HTTP response
     */
    managementCompetitionCreateSubmissionWithHttpInfo(competitionId, submissionCreateSubmissionParam) {
      let postBody = submissionCreateSubmissionParam;

      // verify the required parameter 'competitionId' is set
      if (competitionId === undefined || competitionId === null) {
        throw new Error("Missing the required parameter 'competitionId' when calling managementCompetitionCreateSubmission");
      }

      // verify the required parameter 'submissionCreateSubmissionParam' is set
      if (submissionCreateSubmissionParam === undefined || submissionCreateSubmissionParam === null) {
        throw new Error("Missing the required parameter 'submissionCreateSubmissionParam' when calling managementCompetitionCreateSubmission");
      }


      let pathParams = {
        'competitionId': competitionId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = UtilHttpOkBody;

      return this.apiClient.callApi(
        '/management/competition/{competitionId}/submission', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create competition&#39;s submission
     * @param {Number} competitionId competition id
     * @param {module:model/SubmissionCreateSubmissionParam} submissionCreateSubmissionParam Create submission parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UtilHttpOkBody}
     */
    managementCompetitionCreateSubmission(competitionId, submissionCreateSubmissionParam) {
      return this.managementCompetitionCreateSubmissionWithHttpInfo(competitionId, submissionCreateSubmissionParam)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete competition&#39;s submission
     * @param {Number} competitionId competition id
     * @param {Number} submissionId submission id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UtilHttpRowAffBody} and HTTP response
     */
    managementCompetitionDeleteSubmissionWithHttpInfo(competitionId, submissionId) {
      let postBody = null;

      // verify the required parameter 'competitionId' is set
      if (competitionId === undefined || competitionId === null) {
        throw new Error("Missing the required parameter 'competitionId' when calling managementCompetitionDeleteSubmission");
      }

      // verify the required parameter 'submissionId' is set
      if (submissionId === undefined || submissionId === null) {
        throw new Error("Missing the required parameter 'submissionId' when calling managementCompetitionDeleteSubmission");
      }


      let pathParams = {
        'competitionId': competitionId,
        'submissionId': submissionId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = UtilHttpRowAffBody;

      return this.apiClient.callApi(
        '/management/competition/{competitionId}/submission/{submissionId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete competition&#39;s submission
     * @param {Number} competitionId competition id
     * @param {Number} submissionId submission id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UtilHttpRowAffBody}
     */
    managementCompetitionDeleteSubmission(competitionId, submissionId) {
      return this.managementCompetitionDeleteSubmissionWithHttpInfo(competitionId, submissionId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get specific competition&#39;s submission
     * @param {Number} competitionId competition id
     * @param {Number} submissionId submission id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SubmissionOutputCmsSubmission} and HTTP response
     */
    managementCompetitionSubmissionWithHttpInfo(competitionId, submissionId) {
      let postBody = null;

      // verify the required parameter 'competitionId' is set
      if (competitionId === undefined || competitionId === null) {
        throw new Error("Missing the required parameter 'competitionId' when calling managementCompetitionSubmission");
      }

      // verify the required parameter 'submissionId' is set
      if (submissionId === undefined || submissionId === null) {
        throw new Error("Missing the required parameter 'submissionId' when calling managementCompetitionSubmission");
      }


      let pathParams = {
        'competitionId': competitionId,
        'submissionId': submissionId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = SubmissionOutputCmsSubmission;

      return this.apiClient.callApi(
        '/management/competition/{competitionId}/submission/{submissionId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get specific competition&#39;s submission
     * @param {Number} competitionId competition id
     * @param {Number} submissionId submission id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SubmissionOutputCmsSubmission}
     */
    managementCompetitionSubmission(competitionId, submissionId) {
      return this.managementCompetitionSubmissionWithHttpInfo(competitionId, submissionId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get all competition&#39;s submissions
     * @param {Number} competitionId competition id
     * @param {Object} opts Optional parameters
     * @param {Number} opts.orgId org id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SubmissionOutputCmsSubmissions} and HTTP response
     */
    managementCompetitionSubmissionsWithHttpInfo(competitionId, opts) {
      opts = opts || {};
      let postBody = null;

      // verify the required parameter 'competitionId' is set
      if (competitionId === undefined || competitionId === null) {
        throw new Error("Missing the required parameter 'competitionId' when calling managementCompetitionSubmissions");
      }


      let pathParams = {
        'competitionId': competitionId
      };
      let queryParams = {
        'orgId': opts['orgId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = SubmissionOutputCmsSubmissions;

      return this.apiClient.callApi(
        '/management/competition/{competitionId}/submission', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get all competition&#39;s submissions
     * @param {Number} competitionId competition id
     * @param {Object} opts Optional parameters
     * @param {Number} opts.orgId org id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SubmissionOutputCmsSubmissions}
     */
    managementCompetitionSubmissions(competitionId, opts) {
      return this.managementCompetitionSubmissionsWithHttpInfo(competitionId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update competition&#39;s submission
     * @param {Number} competitionId competition id
     * @param {Number} submissionId submission id
     * @param {module:model/SubmissionUpdateSubmissionParam} submissionUpdateSubmissionParam Update competition&#39;s submission parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UtilHttpRowAffBody} and HTTP response
     */
    managementCompetitionUpdateSubmissionWithHttpInfo(competitionId, submissionId, submissionUpdateSubmissionParam) {
      let postBody = submissionUpdateSubmissionParam;

      // verify the required parameter 'competitionId' is set
      if (competitionId === undefined || competitionId === null) {
        throw new Error("Missing the required parameter 'competitionId' when calling managementCompetitionUpdateSubmission");
      }

      // verify the required parameter 'submissionId' is set
      if (submissionId === undefined || submissionId === null) {
        throw new Error("Missing the required parameter 'submissionId' when calling managementCompetitionUpdateSubmission");
      }

      // verify the required parameter 'submissionUpdateSubmissionParam' is set
      if (submissionUpdateSubmissionParam === undefined || submissionUpdateSubmissionParam === null) {
        throw new Error("Missing the required parameter 'submissionUpdateSubmissionParam' when calling managementCompetitionUpdateSubmission");
      }


      let pathParams = {
        'competitionId': competitionId,
        'submissionId': submissionId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = UtilHttpRowAffBody;

      return this.apiClient.callApi(
        '/management/competition/{competitionId}/submission/{submissionId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update competition&#39;s submission
     * @param {Number} competitionId competition id
     * @param {Number} submissionId submission id
     * @param {module:model/SubmissionUpdateSubmissionParam} submissionUpdateSubmissionParam Update competition&#39;s submission parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UtilHttpRowAffBody}
     */
    managementCompetitionUpdateSubmission(competitionId, submissionId, submissionUpdateSubmissionParam) {
      return this.managementCompetitionUpdateSubmissionWithHttpInfo(competitionId, submissionId, submissionUpdateSubmissionParam)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Upload competition&#39;s submission file
     * @param {Number} competitionId competition id
     * @param {File} file image file
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SubmissionOutputUploadSubmissionFile} and HTTP response
     */
    managementCompetitionUploadSubmissionFileWithHttpInfo(competitionId, file) {
      let postBody = null;

      // verify the required parameter 'competitionId' is set
      if (competitionId === undefined || competitionId === null) {
        throw new Error("Missing the required parameter 'competitionId' when calling managementCompetitionUploadSubmissionFile");
      }

      // verify the required parameter 'file' is set
      if (file === undefined || file === null) {
        throw new Error("Missing the required parameter 'file' when calling managementCompetitionUploadSubmissionFile");
      }


      let pathParams = {
        'competitionId': competitionId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'file': file
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = SubmissionOutputUploadSubmissionFile;

      return this.apiClient.callApi(
        '/management/competition/{competitionId}/submission/file-upload', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Upload competition&#39;s submission file
     * @param {Number} competitionId competition id
     * @param {File} file image file
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SubmissionOutputUploadSubmissionFile}
     */
    managementCompetitionUploadSubmissionFile(competitionId, file) {
      return this.managementCompetitionUploadSubmissionFileWithHttpInfo(competitionId, file)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get all competitions
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CompetitionOutputCmsCompetitions} and HTTP response
     */
    managementCompetitionsWithHttpInfo() {
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = CompetitionOutputCmsCompetitions;

      return this.apiClient.callApi(
        '/management/competition', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get all competitions
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CompetitionOutputCmsCompetitions}
     */
    managementCompetitions() {
      return this.managementCompetitionsWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get all competition
     * @param {module:model/CompetitionCreateCompetitionParam} competitionCreateCompetitionParam Create competition parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UtilHttpOkBody} and HTTP response
     */
    managementCreateCompetitionWithHttpInfo(competitionCreateCompetitionParam) {
      let postBody = competitionCreateCompetitionParam;

      // verify the required parameter 'competitionCreateCompetitionParam' is set
      if (competitionCreateCompetitionParam === undefined || competitionCreateCompetitionParam === null) {
        throw new Error("Missing the required parameter 'competitionCreateCompetitionParam' when calling managementCreateCompetition");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = UtilHttpOkBody;

      return this.apiClient.callApi(
        '/management/competition', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get all competition
     * @param {module:model/CompetitionCreateCompetitionParam} competitionCreateCompetitionParam Create competition parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UtilHttpOkBody}
     */
    managementCreateCompetition(competitionCreateCompetitionParam) {
      return this.managementCreateCompetitionWithHttpInfo(competitionCreateCompetitionParam)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete competition
     * @param {Number} competitionId competition id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UtilHttpRowAffBody} and HTTP response
     */
    managementDeleteCompetitionWithHttpInfo(competitionId) {
      let postBody = null;

      // verify the required parameter 'competitionId' is set
      if (competitionId === undefined || competitionId === null) {
        throw new Error("Missing the required parameter 'competitionId' when calling managementDeleteCompetition");
      }


      let pathParams = {
        'competitionId': competitionId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = UtilHttpRowAffBody;

      return this.apiClient.callApi(
        '/management/competition/{competitionId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete competition
     * @param {Number} competitionId competition id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UtilHttpRowAffBody}
     */
    managementDeleteCompetition(competitionId) {
      return this.managementDeleteCompetitionWithHttpInfo(competitionId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update competition
     * @param {Number} competitionId competition id
     * @param {module:model/CompetitionUpdateCompetitionParam} competitionUpdateCompetitionParam Update competition parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UtilHttpRowAffBody} and HTTP response
     */
    managementUpdateCompetitionWithHttpInfo(competitionId, competitionUpdateCompetitionParam) {
      let postBody = competitionUpdateCompetitionParam;

      // verify the required parameter 'competitionId' is set
      if (competitionId === undefined || competitionId === null) {
        throw new Error("Missing the required parameter 'competitionId' when calling managementUpdateCompetition");
      }

      // verify the required parameter 'competitionUpdateCompetitionParam' is set
      if (competitionUpdateCompetitionParam === undefined || competitionUpdateCompetitionParam === null) {
        throw new Error("Missing the required parameter 'competitionUpdateCompetitionParam' when calling managementUpdateCompetition");
      }


      let pathParams = {
        'competitionId': competitionId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = UtilHttpRowAffBody;

      return this.apiClient.callApi(
        '/management/competition/{competitionId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update competition
     * @param {Number} competitionId competition id
     * @param {module:model/CompetitionUpdateCompetitionParam} competitionUpdateCompetitionParam Update competition parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UtilHttpRowAffBody}
     */
    managementUpdateCompetition(competitionId, competitionUpdateCompetitionParam) {
      return this.managementUpdateCompetitionWithHttpInfo(competitionId, competitionUpdateCompetitionParam)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
