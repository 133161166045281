/**
 * Big Waster API
 * This is a Big Waster API.
 *
 * OpenAPI spec version: 1.0
 * Contact: terry.wong@futureimpactlab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import UserCreateUserParam from '../model/UserCreateUserParam';
import UserOutputCmsUser from '../model/UserOutputCmsUser';
import UserOutputCmsUsers from '../model/UserOutputCmsUsers';
import UserUpdateUserParam from '../model/UserUpdateUserParam';
import UtilHttpErrorBody from '../model/UtilHttpErrorBody';
import UtilHttpRowAffBody from '../model/UtilHttpRowAffBody';
import UtilHttpRowIdBody from '../model/UtilHttpRowIdBody';

/**
* ManagementUser service.
* @module api/ManagementUserApi
* @version 1.0
*/
export default class ManagementUserApi {

    /**
    * Constructs a new ManagementUserApi. 
    * @alias module:api/ManagementUserApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Create user
     * @param {module:model/UserCreateUserParam} userCreateUserParam Create user parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UtilHttpRowIdBody} and HTTP response
     */
    managementCreateUserWithHttpInfo(userCreateUserParam) {
      let postBody = userCreateUserParam;

      // verify the required parameter 'userCreateUserParam' is set
      if (userCreateUserParam === undefined || userCreateUserParam === null) {
        throw new Error("Missing the required parameter 'userCreateUserParam' when calling managementCreateUser");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = UtilHttpRowIdBody;

      return this.apiClient.callApi(
        '/management/user', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create user
     * @param {module:model/UserCreateUserParam} userCreateUserParam Create user parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UtilHttpRowIdBody}
     */
    managementCreateUser(userCreateUserParam) {
      return this.managementCreateUserWithHttpInfo(userCreateUserParam)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete user
     * @param {Number} userId user id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UtilHttpRowAffBody} and HTTP response
     */
    managementDeleteUserWithHttpInfo(userId) {
      let postBody = null;

      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling managementDeleteUser");
      }


      let pathParams = {
        'userId': userId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = UtilHttpRowAffBody;

      return this.apiClient.callApi(
        '/management/user/{userId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete user
     * @param {Number} userId user id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UtilHttpRowAffBody}
     */
    managementDeleteUser(userId) {
      return this.managementDeleteUserWithHttpInfo(userId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update user
     * @param {Number} userId user id
     * @param {module:model/UserUpdateUserParam} userUpdateUserParam Update user parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UtilHttpRowAffBody} and HTTP response
     */
    managementUpdateUserWithHttpInfo(userId, userUpdateUserParam) {
      let postBody = userUpdateUserParam;

      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling managementUpdateUser");
      }

      // verify the required parameter 'userUpdateUserParam' is set
      if (userUpdateUserParam === undefined || userUpdateUserParam === null) {
        throw new Error("Missing the required parameter 'userUpdateUserParam' when calling managementUpdateUser");
      }


      let pathParams = {
        'userId': userId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = UtilHttpRowAffBody;

      return this.apiClient.callApi(
        '/management/user/{userId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update user
     * @param {Number} userId user id
     * @param {module:model/UserUpdateUserParam} userUpdateUserParam Update user parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UtilHttpRowAffBody}
     */
    managementUpdateUser(userId, userUpdateUserParam) {
      return this.managementUpdateUserWithHttpInfo(userId, userUpdateUserParam)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get specific user
     * @param {Number} userId user id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserOutputCmsUser} and HTTP response
     */
    managementUserWithHttpInfo(userId) {
      let postBody = null;

      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling managementUser");
      }


      let pathParams = {
        'userId': userId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = UserOutputCmsUser;

      return this.apiClient.callApi(
        '/management/user/{userId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get specific user
     * @param {Number} userId user id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserOutputCmsUser}
     */
    managementUser(userId) {
      return this.managementUserWithHttpInfo(userId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get all user
     * @param {Object} opts Optional parameters
     * @param {Number} opts.org org id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserOutputCmsUsers} and HTTP response
     */
    managementUsersWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
        'org': opts['org']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = UserOutputCmsUsers;

      return this.apiClient.callApi(
        '/management/user', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get all user
     * @param {Object} opts Optional parameters
     * @param {Number} opts.org org id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserOutputCmsUsers}
     */
    managementUsers(opts) {
      return this.managementUsersWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
