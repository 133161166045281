import React from "react"

import Management from "~/src/page/Management"
import NotFound from "~/src/components/NotFound"
import Login from "~/src/page/Login";

export default [
    {
        component: Login,
        path: "/",
        exact: true,
        strict: true,
    },
    {
        component: Management,
        path: "/management",
        strict: true,
    },
    {
        path: "*",
        component: NotFound
    },
];