import {actionType} from "../action"

let loadingCount = 0;
export default function orgPageIsLoading(state = true, {type, isLoading, error}) {
    switch (type) {
        case actionType.SET_ORG_PAGE_IS_LOADING:
            if(isLoading){
                loadingCount++;
            } else {
                loadingCount--;
            }

            if(loadingCount > 0){
                return true;
            }

            if(loadingCount <= 0){
                return false;
            }

        case actionType.RESET_ORG_PAGE_IS_LOADING:
            loadingCount = 0;
            return isLoading;
        default:
            return state
    }
}