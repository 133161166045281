import {
    Form, Icon, Input, Button, Checkbox, Row, Col
} from 'antd';

import imgBigwasterLogo from './bigwaster-logo.png'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {login} from '~/src/redux/action'
import React, {Component} from 'react'
@compose(
    connect(
        (state, ownProps) => {
            return {
                userDisplayName: state.user.name,
                role: state.user.role,
                isLoggingIn: state.login.isLoggingIn,
                error: state.login.error,
            }
        },
        (dispatch, ownProps) => {
            return {
                onLoginClick: (username, password) => {
                    dispatch(login(username, password))
                }
            }
        }
    ),
    Form.create()
)
class Login extends Component {

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({isLoggingIn: true})
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onLoginClick(values.username, values.password);
            } else {
                this.setState({isLoggingIn: false})
            }
        });
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        const {isLoggingIn, error} = this.props;
        return (
            <Row type="flex" justify="space-around" align="middle" style={{height: '100vh', padding: 10}}>
                <Col span={8} sm={24} xl={8}>
                    <Row>
                        <Col span={24} sm={24} xl={24}>
                            <img src={imgBigwasterLogo}/>
                        </Col>
                        <Col span={24} sm={24} xl={24}>
                            {error}
                        </Col>
                    </Row>
                    <Form onSubmit={this.handleSubmit} className="login-form">
                        <Form.Item>
                            {getFieldDecorator('username', {
                                rules: [{required: true, message: 'Please input your username!'}],
                            })(
                                <Input prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                       placeholder="Username"/>
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('password', {
                                rules: [{required: true, message: 'Please input your Password!'}],
                            })(
                                <Input prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>} type="password"
                                       placeholder="Password"/>
                            )}
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button" disabled={isLoggingIn}>
                                {
                                    isLoggingIn ? <Icon type="loading" style={{color: '#fff'}} spin />: 'Login'
                                }
                            </Button>
                        </Form.Item>
                    </Form></Col>
            </Row>
        );
    }
}

export default Login;