
// Create and Update Form
import {Button, DatePicker, Drawer, Form, Input, notification, Select} from "antd";
import React, {Component} from "react";
import {FormType} from "~/src/utils/form";
import ManagementHelperApi from "~/src/api-client/api/ManagementHelperApi";
import moment from "moment";

@Form.create()
class CUForm extends Component {
    static defaultProps = {
        formType: FormType.create,
        org: {id: -1, name: ''},
        data: {
            username: '',
            name: '',
        }
    };

    create = (data) => {
        // Create waste record
        new ManagementHelperApi().managementHelperCreateRefundedBag(data).then(() => {
            if (this.props.onSuccess) {
                this.props.onSuccess()
            }
            notification.success({message: `${data.date} was successfully created`})
        }).catch((err) => {
            notification.warning({message: `Cannot create ${data.date}`, description: `${err}`, duration: 0});
        })
    };

    modify = (data) => {
        // Update waste record
        new ManagementHelperApi().managementHelperUpdateRefundedBag(this.props.data.id, data).then(() => {
            if (this.props.onSuccess) {
                this.props.onSuccess()
            }
            notification.success({message: `${data.date} was successfully modified`})
        }).catch((err) => {
            notification.warning({
                message: `Cannot modify  ${this.props.data.date}`,
                description: `${err}`,
                duration: 0
            });
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (err) {
                return;
            }

            values.date = moment(values.date).utc().toISOString();
            console.log('Received values of form: ', values);
            this[this.props.formType](values)
        });
    }

    render() {
        const {orgUsers} = this.props;
        const {getFieldDecorator} = this.props.form;
        const {userId, quantity, bagType, date} = this.props.data;

        return <Drawer
            title={this.props.title}
            onClose={this.props.onClose}
            visible={this.props.isOpen}
        >
            <Form layout="vertical" onSubmit={this.handleSubmit}>
                <Form.Item label="Date" colon={false}>
                    {getFieldDecorator('date', {
                        rules: [{required: true}], initialValue: moment(date),
                    })(
                        <DatePicker.MonthPicker />
                    )}
                </Form.Item>
                <Form.Item label="Class" hasFeedback colon={false}>
                    {getFieldDecorator('userId', {rules: [{required: true}], initialValue: userId})(
                        <Select>
                            {
                                orgUsers.map((v, i) => {
                                    return <Select.Option key={i} value={v.id}>{v.name}</Select.Option>
                                })
                            }
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label="Bag type" hasFeedback colon={false}>
                    {getFieldDecorator('bagType', {
                        rules: [{required: true}], initialValue: bagType
                    })(
                        <Select>
                            <Select.Option value="3L">3L</Select.Option>
                            <Select.Option value="5L">5L</Select.Option>
                            <Select.Option value="10L">10L</Select.Option>
                            <Select.Option value="35L">35L</Select.Option>
                            <Select.Option value="20L">20L</Select.Option>
                            <Select.Option value="75L">75L</Select.Option>
                            <Select.Option value="100L">100L</Select.Option>
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label="Quantity" hasFeedback colon={false}>
                    {getFieldDecorator('quantity', {
                        rules: [{required: true}], initialValue: quantity, normalize: (value) => {
                            return parseFloat(value)
                        }
                    })(
                        <Input type="number"/>
                    )}
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">Submit</Button>
                </Form.Item>
            </Form>
        </Drawer>
    }
}

export default CUForm;