import React, {Component} from 'react'
import './TitleBar.scss'
import {Row, Col} from 'antd'
import ReactDOM from "react-dom";

class TitleBar extends Component {
    titleBarNode = null;
    titleBarNodeInitialBoundingClientRect = null;
    state = {isOnCanvasTop: false};
    componentDidMount() {
        if(this.refs.titleBar == null){
            return
        }
        
        this.titleBarNode = ReactDOM.findDOMNode(this.refs.titleBar);
        //console.log(this.titleBarNode.offsetTop);
        this.titleBarNodeInitialBoundingClientRect = this.titleBarNode.getBoundingClientRect();

        window.addEventListener('scroll', this.handleScroll);

    }

    componentWillUnmount() {
        if(this.titleBarNode == null) {
            return
        }

        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = (e) => {
        let top  = window.pageYOffset || document.documentElement.scrollTop;
        const boundingClientRect = this.titleBarNode.getBoundingClientRect();
        if(boundingClientRect.y <= 0){
            if(!this.state.isOnCanvasTop){
                this.setState({isOnCanvasTop: true})
            }
        } else if(top < this.titleBarNode.offsetTop/2) {
            if(this.state.isOnCanvasTop){
                this.setState({isOnCanvasTop: false})
            }
        }
    };

    render() {
        const {isOnCanvasTop} = this.state;
        let titleBarClassName = 'title-bar';
        if(isOnCanvasTop) {
            titleBarClassName += ' floating';
        }
        return <Row type="flex" ref="titleBar" justify="start" align="middle" className={titleBarClassName}>
            <Col span={24}><h1>{this.props.title}</h1></Col>
        </Row>
    }
}

export default TitleBar;
