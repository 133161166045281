import React, {Component} from 'react'
import {connect} from 'react-redux'
import {isEmpty} from 'lodash'
import Menu from './menu'
import TitleBar from '~/src/components/TitleBar'
import {renderRoutes} from "react-router-config";
import {fetchCurrentOrg, setOrgPageIsLoading, resetOrgPageIsLoading, logout} from "~/src/redux/action";
import { matchPath } from "react-router";
import LoadingBar from "~/src/components/LoadingBar";

@connect(
    (state) => {
        return {
            init: state.init,
            userInfo:state.user,
            orgInfo: state.orgInfo,
            orgPageIsLoading: state.orgPageIsLoading,
        }
    },
    (dispatch, ownProps) => {
        return {
            resetOrgPageIsLoading: (isLoading) => {
                return dispatch(resetOrgPageIsLoading(isLoading))
            },
            setOrgPageIsLoading: (isLoading) => {
                return dispatch(setOrgPageIsLoading(isLoading))
            },
            fetchCurrentOrg: (orgId) => {
                return dispatch(fetchCurrentOrg(orgId))
            },
            logout: () => {
                return dispatch(logout())
            }
        }
    }
)
class ManagementOrg extends Component {
    componentDidMount() {
        if(this.props.init == true) {
            const orgId = this.props.match.params.orgId;
            this.props.fetchCurrentOrg(orgId)
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.location.pathname !== this.props.location.pathname){
            this.props.resetOrgPageIsLoading(true)
        }

        if(prevProps.init == false && this.props.init ==true) {
            const orgId = this.props.match.params.orgId;
            this.props.fetchCurrentOrg(orgId)
        }
    }

    componentWillUnmount(prevProps){
        this.props.resetOrgPageIsLoading(true)
    }

    render() {
        const {route, match, history, orgInfo, orgPageIsLoading, userInfo, init} = this.props;
        const orgId = match.params.orgId;
        if(!init){
           return <LoadingBar show={true} />
        }

        if(this.props.userInfo.org && this.props.userInfo.org.id != orgId){
            this.props.logout()
        }
        if(this.props.userInfo.role == null){
            this.props.logout()
        }

        if (isEmpty(orgInfo) || orgInfo.id != orgId) {
            return <LoadingBar show={true} />
        }

        if(this.props.userInfo.role == "helper"){
            if(
                matchPath(history.location.pathname, {
                path: "/management/org/:orgId/usage/waste",
                exact: true,
                strict: true
                }) == null
                &&
                matchPath(history.location.pathname, {
                    path: "/management/org/:orgId/usage/bag",
                    exact: true,
                    strict: true
                }) == null
            ) {
                this.props.logout()
            }
        }

        if(this.props.userInfo.role == "schoolAdmin"){
            if(
                matchPath(history.location.pathname, {
                    path: "/management/org/:orgId/statement",
                    exact: true,
                    strict: true
                }) == null
                &&
                matchPath(history.location.pathname, {
                    path: "/management/org/:orgId/bag-order",
                    exact: true,
                    strict: true
                }) == null
            ) {
                this.props.logout()
            }
        }

        return <div>
            <TitleBar title={orgInfo.name}/>
            {
                userInfo.role == 'admin' && <Menu.Admin />
            }
            {
                userInfo.role == 'helper' && <Menu.Helper />
            }
            {
                userInfo.role == 'schoolAdmin' && <Menu.SchoolAdmin />
            }

            {orgPageIsLoading && <LoadingBar show={orgPageIsLoading} />}
            {renderRoutes(route.routes, this.props)}
        </div>
    }
}

export default ManagementOrg;