/**
 * Big Waster API
 * This is a Big Waster API.
 *
 * OpenAPI spec version: 1.0
 * Contact: terry.wong@futureimpactlab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import OrgCreateOrgParam from '../model/OrgCreateOrgParam';
import OrgOutputOrg from '../model/OrgOutputOrg';
import OrgOutputOrgs from '../model/OrgOutputOrgs';
import OrgUpdateOrgParam from '../model/OrgUpdateOrgParam';
import UtilHttpErrorBody from '../model/UtilHttpErrorBody';
import UtilHttpOkBody from '../model/UtilHttpOkBody';
import UtilHttpRowAffBody from '../model/UtilHttpRowAffBody';

/**
* ManagementOrg service.
* @module api/ManagementOrgApi
* @version 1.0
*/
export default class ManagementOrgApi {

    /**
    * Constructs a new ManagementOrgApi. 
    * @alias module:api/ManagementOrgApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Get specific org
     * @param {Number} orgId org id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/OrgOutputOrg} and HTTP response
     */
    managementOrgWithHttpInfo(orgId) {
      let postBody = null;

      // verify the required parameter 'orgId' is set
      if (orgId === undefined || orgId === null) {
        throw new Error("Missing the required parameter 'orgId' when calling managementOrg");
      }


      let pathParams = {
        'orgId': orgId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = OrgOutputOrg;

      return this.apiClient.callApi(
        '/management/org/{orgId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get specific org
     * @param {Number} orgId org id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/OrgOutputOrg}
     */
    managementOrg(orgId) {
      return this.managementOrgWithHttpInfo(orgId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create Org
     * @param {module:model/OrgCreateOrgParam} orgCreateOrgParam Create org parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UtilHttpOkBody} and HTTP response
     */
    managementOrgCreateWithHttpInfo(orgCreateOrgParam) {
      let postBody = orgCreateOrgParam;

      // verify the required parameter 'orgCreateOrgParam' is set
      if (orgCreateOrgParam === undefined || orgCreateOrgParam === null) {
        throw new Error("Missing the required parameter 'orgCreateOrgParam' when calling managementOrgCreate");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = UtilHttpOkBody;

      return this.apiClient.callApi(
        '/management/org', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create Org
     * @param {module:model/OrgCreateOrgParam} orgCreateOrgParam Create org parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UtilHttpOkBody}
     */
    managementOrgCreate(orgCreateOrgParam) {
      return this.managementOrgCreateWithHttpInfo(orgCreateOrgParam)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete org
     * @param {Number} orgId org id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UtilHttpRowAffBody} and HTTP response
     */
    managementOrgDeleteWithHttpInfo(orgId) {
      let postBody = null;

      // verify the required parameter 'orgId' is set
      if (orgId === undefined || orgId === null) {
        throw new Error("Missing the required parameter 'orgId' when calling managementOrgDelete");
      }


      let pathParams = {
        'orgId': orgId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = UtilHttpRowAffBody;

      return this.apiClient.callApi(
        '/management/org/{orgId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete org
     * @param {Number} orgId org id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UtilHttpRowAffBody}
     */
    managementOrgDelete(orgId) {
      return this.managementOrgDeleteWithHttpInfo(orgId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update Org
     * @param {Number} orgId org id
     * @param {module:model/OrgUpdateOrgParam} orgUpdateOrgParam Update org parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UtilHttpRowAffBody} and HTTP response
     */
    managementOrgUpdateWithHttpInfo(orgId, orgUpdateOrgParam) {
      let postBody = orgUpdateOrgParam;

      // verify the required parameter 'orgId' is set
      if (orgId === undefined || orgId === null) {
        throw new Error("Missing the required parameter 'orgId' when calling managementOrgUpdate");
      }

      // verify the required parameter 'orgUpdateOrgParam' is set
      if (orgUpdateOrgParam === undefined || orgUpdateOrgParam === null) {
        throw new Error("Missing the required parameter 'orgUpdateOrgParam' when calling managementOrgUpdate");
      }


      let pathParams = {
        'orgId': orgId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = UtilHttpRowAffBody;

      return this.apiClient.callApi(
        '/management/org/{orgId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update Org
     * @param {Number} orgId org id
     * @param {module:model/OrgUpdateOrgParam} orgUpdateOrgParam Update org parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UtilHttpRowAffBody}
     */
    managementOrgUpdate(orgId, orgUpdateOrgParam) {
      return this.managementOrgUpdateWithHttpInfo(orgId, orgUpdateOrgParam)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get org list
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/OrgOutputOrgs} and HTTP response
     */
    managementOrgsWithHttpInfo() {
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = OrgOutputOrgs;

      return this.apiClient.callApi(
        '/management/org', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get org list
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/OrgOutputOrgs}
     */
    managementOrgs() {
      return this.managementOrgsWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
