import {actionType} from "~/src/redux/action";

export default function (state = {}, {type, user, error}) {
    switch (type) {
        case actionType.FETCH_LOGIN_USER_SUCCESS:
            return {
                ...state,
                ...user
            };
        case actionType.FETCH_LOGIN_USER_ERROR:
            return {
                ...state,
                ...{error: error}
            };
        default:
            return state
    }
}
