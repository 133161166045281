import {actionType} from "../action"
export default function OrgInfo(state = {}, {type, org, error}) {
    switch (type) {
        case actionType.FETCH_CURRENT_ORG_SUCCESS:
            return {
                ...state,
                ...org
            };
        case actionType.FETCH_CURRENT_ORG_ERROR:
            return {
                ...state,
                ...{error: error}
            };
        case actionType.RESET_CURRENT_ORG:
            return {};
        default:
            return state
    }
}