
// Create and Update Form
import {Button, DatePicker, Drawer, Form, Input, notification, Select, Switch} from "antd";
import React, {Component} from "react";
import {FormType} from "~/src/utils/form";
import ShopApi from "~/src/api-client/api/ManagementShopApi";
import moment from "moment";
import {ManagementOrgBagUsageApi} from "~/src/api-client";
import ManagementOrgBagOrderApi from "~/src/api-client/api/ManagementOrgBagOrderApi";
import _ from 'lodash';

@Form.create()
class CUForm extends Component {
    static defaultProps = {
        formType: FormType.create,
        data: {

        }
    };

    state = {
        haveShopper: false,
    }

    create = (data) => {
        return Promise.all(_.map(this.props.itemList, item => {
            if (data[item] !== 0) {
                return new ManagementOrgBagOrderApi().managementOrgBagOrderCreate(this.props.org.id, {
                    userId: data.userId,
                    bagType: item,
                    quantity: parseInt(data[item]),
                    date: moment(data.date).startOf('day').toDate(),
                    isDelivered: data.isDelivered
                });
            }
        }))
        .then(() => {
            if (this.props.onSuccess) {
                this.props.onSuccess()
            }
            notification.success({message: `The order was successfully created`})
        }).catch((err) => {
            notification.warning({message: `Cannot create ${data.name}`, description: `${err}`, duration: 0});
        });
    };

    modify = (data) => {
        const targetBagOrders = _.filter(this.props.bagOrders, order => moment(order.date).format('YYYY-MM-DD') === this.props.data.date && order.userId === this.props.data.userId);
        const updatePromises = _.map(targetBagOrders, order => {
            if (data[order.bagType] === 0) {
                return new ManagementOrgBagOrderApi().managementOrgBagOrderDelete(this.props.org.id, order.id);
            } else {
                return new ManagementOrgBagOrderApi().managementOrgBagOrderUpdate(this.props.org.id, order.id, {
                    userId: data.userId,
                    bagType: order.bagType,
                    quantity: parseInt(data[order.bagType]),
                    date: moment(data.date).startOf('day').toDate(),
                    isDelivered: data.isDelivered
                });
            }
        });
        const createPromises = _.map(this.props.itemList, item => {
            if ((_.findIndex(targetBagOrders, order => order.bagType === item) === -1) && data[item] !== 0) {
                return new ManagementOrgBagOrderApi().managementOrgBagOrderCreate(this.props.org.id, {
                    userId: data.userId,
                    bagType: item,
                    quantity: parseInt(data[item]),
                    date: moment(data.date).startOf('day').toDate(),
                    isDelivered: data.isDelivered
                });
            }
        });
        return Promise.all(_.concat(updatePromises, createPromises))
        .then(() => {
            if (this.props.onSuccess) {
                this.props.onSuccess()
            }
            notification.success({message: `The order was successfully modified`})
        }).catch((err) => {
            notification.warning({
                message: `Cannot modify  ${this.props.data.name}`,
                description: `${err}`,
                duration: 0
            });
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (err) {
                return;
            }

            console.log('Received values of form: ', values);
            this[this.props.formType](values)
        });
    }

    componentDidMount() {
        // Class list
    }

    render() {
        // Order Date
        // Class
        // Item
        // Qty
        // Revised Qty
        // Status
        const {getFieldDecorator} = this.props.form;
        const {userId, bagType , quantity, date, isDelivered, ...rest} = this.props.data;
        const {orgUsers, itemList} = this.props;

        return <Drawer
            title={this.props.title}
            width={720}
            onClose={this.props.onClose}
            visible={this.props.isOpen}
        >
            <Form layout="vertical" onSubmit={this.handleSubmit}>
                <Form.Item label="Order Date" hasFeedback colon={false}>
                    {getFieldDecorator('date', {rules: [{required: true}], initialValue: date ? moment(date): null})(
                        <DatePicker />
                    )}
                </Form.Item>
                <Form.Item label="Class / School" hasFeedback colon={false}>
                    {
                        getFieldDecorator('userId', {rules: [{required: true}], initialValue: userId ? userId: null})(
                        <Select>
                            {
                                orgUsers.map((v, i) => {
                                    return <Select.Option key={i} value={v.id}>{v.name}</Select.Option>
                                })
                            }
                        </Select>
                    )}
                </Form.Item>
                {
                    _.map(itemList, (item, i) => (
                        <Form.Item key={item} label={item} hasFeedback colon={false}>
                            {getFieldDecorator(item, {initialValue: rest[item] ? rest[item]: 0})(
                                <Input type="number" min={0}/>
                            )}
                        </Form.Item>
                    ))
                }
                <Form.Item label="Delivered?" colon={false}>
                    {
                        getFieldDecorator('isDelivered', {rules: [{required: true}], initialValue: isDelivered === false ? isDelivered : true, valuePropName: 'checked'})(
                            <Switch />
                        )
                    }
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">Submit</Button>
                </Form.Item>
            </Form>
        </Drawer>
    }
}

export default CUForm;