import React, {Component} from 'react'
import DataTable, {filterType} from "~/src/components/DataTable";
import {Button, Menu, Icon, Drawer, Form, Input, DatePicker, Select, notification} from 'antd'
import _ from 'lodash'

import ManagementUserApi from "~/src/api-client/api/ManagementUserApi"

import {FormType} from "~/src/utils/form"
import {OperationColumn} from '~/src/utils/data-table'

import ManagementHelperApi from "~/src/api-client/api/ManagementHelperApi"
import moment from 'moment'
import csv from "~/src/csv"
import CUForm from "./Form";
//
// @compose(
//     connect((state, props) => {
//             return {
//                 org: state.currentOrg,
//             }
//         }
//     ),
//     withPanel,
//     wrapper({selectedMenuItem: 'waste-usage'}),
//     newInitFetch([
//         {
//             name: 'wasteRecords',
//             fn: (component, router) => {
//                 return component.WasteRecords(router.query.orgId)
//             }
//         },
//         {
//             name: 'orgUsers',
//             fn: (component, router) => {
//                 return component.OrgUsers(router.query.orgId)
//             }
//         }
//     ])
// )
class OrgManagementWasteUsage extends Component {
    state = {
        filteredValue: {},
        wasteRecords: [],
        orgUsers:[],
        isOpenCreateDrawer: false,
        isOpenUpdateDrawer: false,
        modifyFormData: {}
    };

    static OrgUsers(orgId) {
        return new ManagementUserApi().managementUsers({org: orgId}).then(({users: allUsers}) => {
            let users = _(allUsers).filter((v)=> v.role == 'user' || v.role == 'org').value();
            return users;
        })
    }

    static WasteRecords(orgId) {
        return new ManagementHelperApi().managementHelperWasteRecords({orgId: orgId}).then(({records}) => {
            return records;
        })
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.constructor.OrgUsers(this.props.orgInfo.id).then((orgUsers)=>{
            this.setState({orgUsers: orgUsers});
        });
        this.refreshData()
    }

    handleOperationBarOnClick = ({item, key}) => {
        if (key == FormType.create) {
            this.setState({isOpenCreateDrawer: true})
        }

        if (key == csv.downloadType) {
            csv.download(this.state.wasteRecords.map((v, k)=>{

                v.date = moment(v.date).format('YYYY-MM-DD');

                if(!v.class){
                    v.class = 'Non-classroom'
                }

                if(!v.paper){
                    v.paper = '0'
                }

                if(!v.plastic){
                    v.plastic = '0'
                }

                if(!v.metal){
                    v.metal = '0'
                }

                if(!v.waste){
                    v.waste = '0'
                }

                return v
            }), ['Date', 'Class', 'Paper', 'Plastic', 'Metal', 'Waste'], ['date', 'class', 'paper', 'plastic', 'metal', 'waste'], `${this.props.orgInfo.name}-${moment().format('YYYY-MM-DD')}-wasteUsage`)
        }
    };

    handleRecordModifyOnClick = (record) => {
        this.setState({isOpenUpdateDrawer: true, modifyFormData: record})
    };

    handleCUFormOnClose = () => {
        this.setState({isOpenCreateDrawer: false, isOpenUpdateDrawer: false})
    };

    handleDataChange = () => {
        this.refreshData();
        this.setState({isOpenCreateDrawer: false, isOpenUpdateDrawer: false})
    };

    refreshData = () => {
        this.props.setOrgPageIsLoading(true);
        this.constructor.WasteRecords(this.props.match.params.orgId).then((wasteRecords) => {
            this.setState({wasteRecords: wasteRecords});
        }).catch((err) => {
            notification.warning({message: `Cannot refresh data`, description: `${err}`, duration: 0});
        }).finally(()=>{
            this.props.setOrgPageIsLoading(false);
        })
    };

    handleDeleteRecord = (record) => {
        this.props.setOrgPageIsLoading(true);
        return new ManagementHelperApi().managementHelperDeleteWasteRecord(record.id).then(() => {
            return null
        }).then(() => {
            notification.success({message: `${record.name} was successfully deleted`})
        }).catch((err) => {
            notification.warning({message: `Cannot delete ${record.date}`, description: `${err}`, duration: 0});
        }).finally(() => {
            this.props.setOrgPageIsLoading(false);
            this.refreshData();
        })
    };

    render() {
        const {modifyFormData, isOpenCreateDrawer, isOpenUpdateDrawer, result, filteredValue, orgUsers, wasteRecords} = this.state;
        const {orgInfo} = this.props;
        const columns = [{
            title: 'Date',
            dataIndex: 'date',
            render: (date, record) => moment(date).format('YYYY-MM-DD'),
            sorter: (a, b) => new Date(a.date) - new Date(b.date),
        }, {
            title: 'Class',
            dataIndex: 'userId',
            filter: {
                type: filterType.Checkbox,
                options: _(orgUsers).map('name').uniq().value(),
                onChange: (v) => {
                    let filterValue = [];
                    v.forEach((k)=>{
                        const user = _.find(orgUsers, {name: k});
                        if(user){
                            filterValue.push(user.id)
                        }
                    })
                    this.setState({filteredValue: Object.assign(this.state.filteredValue, {'class': filterValue})}
                    )
                }
            },
            filteredValue: this.state.filteredValue.class,
            onFilter: (value, record) => {
                return value == record.userId
            },
            sorter: (a, b) => {
                const userA = _.find(orgUsers, {id: a.userId});
                const userB = _.find(orgUsers, {id: b.userId});

                if(userA && userB){
                   return userA.name.localeCompare(userB.name)
                }
                return false
            },
            render: (userId) => {
                const user = _.find(orgUsers, {id: userId});
                if(user){
                    return user.name
                }
                return '-'
            }
        }, {
            title: 'Paper',
            dataIndex: 'paper',
            sorter: (a, b) => a.paper - b.paper,
            render: (text) => `${text == null ? '-': text} kg`,
        }, {
            title: 'Plastic',
            dataIndex: 'plastic',
            sorter: (a, b) => a.plastic - b.plastic,
            render: (text) => `${text == null ? '-': text} kg`,
        }, {
            title: 'Metal',
            dataIndex: 'metal',
            sorter: (a, b) => a.metal - b.metal,
            render: (text) => `${text == null ? '-': text} kg`,
        }, {
            title: 'Waste',
            dataIndex: 'waste',
            sorter: (a, b) => a.waste - b.waste,
            render: (text) => `${text == null ? 0: text} kg`,

        }, OperationColumn(null, this.handleRecordModifyOnClick, this.handleDeleteRecord)];

        return <div>
            <DataTable operationBarComponent={<OperationBarComponent onClick={this.handleOperationBarOnClick}/>}
                       columns={columns}
                       data={wasteRecords}
                       pageSize={50}
                       hideFilter={false}
                       rowKey="id"
            />

            {/* Create Form */}
            {isOpenCreateDrawer &&
            <CUForm
                title={'Create a new waste record'}
                isOpen={isOpenCreateDrawer}
                formType={FormType.create}
                org={orgInfo}
                orgUsers={orgUsers}
                onClose={this.handleCUFormOnClose}
                onSuccess={this.handleDataChange}
            />}

            {/* Update Form */}
            {isOpenUpdateDrawer &&
            <CUForm
                title={'Modify a waste record'}
                data={modifyFormData}
                org={orgInfo}
                orgUsers={orgUsers}
                isOpen={isOpenUpdateDrawer}
                formType={FormType.modify}
                onClose={this.handleCUFormOnClose}
                onSuccess={this.handleDataChange}
            />}

        </div>
    }
}

const OperationBarComponent = (props) => {
    return <Menu
        mode="horizontal"
        selectable={false}
        onClick={props.onClick}
    >
        <Menu.Item key={FormType.create}>
            <Icon type="plus"/>Create
        </Menu.Item>
        <Menu.Item key={csv.downloadType}>
            <Icon type="download"/>Download
        </Menu.Item>
    </Menu>
};

export default OrgManagementWasteUsage;
