/**
 * Big Waster API
 * This is a Big Waster API.
 *
 * OpenAPI spec version: 1.0
 * Contact: terry.wong@futureimpactlab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import BagusageCreateBagUsageParam from '../model/BagusageCreateBagUsageParam';
import BagusageOutput from '../model/BagusageOutput';
import BagusageUpdateBagUsageParam from '../model/BagusageUpdateBagUsageParam';
import UtilHttpErrorBody from '../model/UtilHttpErrorBody';

/**
* ManagementOrgBagUsage service.
* @module api/ManagementOrgBagUsageApi
* @version 1.0
*/
export default class ManagementOrgBagUsageApi {

    /**
    * Constructs a new ManagementOrgBagUsageApi. 
    * @alias module:api/ManagementOrgBagUsageApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Create bag usages
     * @param {Number} orgId org id
     * @param {module:model/BagusageCreateBagUsageParam} bagusageCreateBagUsageParam Create bag Usage parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    managementOrgBagUsageCreateWithHttpInfo(orgId, bagusageCreateBagUsageParam) {
      let postBody = bagusageCreateBagUsageParam;

      // verify the required parameter 'orgId' is set
      if (orgId === undefined || orgId === null) {
        throw new Error("Missing the required parameter 'orgId' when calling managementOrgBagUsageCreate");
      }

      // verify the required parameter 'bagusageCreateBagUsageParam' is set
      if (bagusageCreateBagUsageParam === undefined || bagusageCreateBagUsageParam === null) {
        throw new Error("Missing the required parameter 'bagusageCreateBagUsageParam' when calling managementOrgBagUsageCreate");
      }


      let pathParams = {
        'orgId': orgId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;

      return this.apiClient.callApi(
        '/management/org/{orgId}/bag-usage', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create bag usages
     * @param {Number} orgId org id
     * @param {module:model/BagusageCreateBagUsageParam} bagusageCreateBagUsageParam Create bag Usage parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    managementOrgBagUsageCreate(orgId, bagusageCreateBagUsageParam) {
      return this.managementOrgBagUsageCreateWithHttpInfo(orgId, bagusageCreateBagUsageParam)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete bag usages
     * @param {Number} orgId org id
     * @param {Number} bagUsageId bagUsageId
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    managementOrgBagUsageDeleteWithHttpInfo(orgId, bagUsageId) {
      let postBody = null;

      // verify the required parameter 'orgId' is set
      if (orgId === undefined || orgId === null) {
        throw new Error("Missing the required parameter 'orgId' when calling managementOrgBagUsageDelete");
      }

      // verify the required parameter 'bagUsageId' is set
      if (bagUsageId === undefined || bagUsageId === null) {
        throw new Error("Missing the required parameter 'bagUsageId' when calling managementOrgBagUsageDelete");
      }


      let pathParams = {
        'orgId': orgId,
        'bagUsageId': bagUsageId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = null;

      return this.apiClient.callApi(
        '/management/org/{orgId}/bag-usage/{bagUsageId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete bag usages
     * @param {Number} orgId org id
     * @param {Number} bagUsageId bagUsageId
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    managementOrgBagUsageDelete(orgId, bagUsageId) {
      return this.managementOrgBagUsageDeleteWithHttpInfo(orgId, bagUsageId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update bag usages
     * @param {Number} orgId org id
     * @param {Number} bagUsageId bagUsageId
     * @param {module:model/BagusageUpdateBagUsageParam} bagusageUpdateBagUsageParam Update bag Usage parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    managementOrgBagUsageUpdateWithHttpInfo(orgId, bagUsageId, bagusageUpdateBagUsageParam) {
      let postBody = bagusageUpdateBagUsageParam;

      // verify the required parameter 'orgId' is set
      if (orgId === undefined || orgId === null) {
        throw new Error("Missing the required parameter 'orgId' when calling managementOrgBagUsageUpdate");
      }

      // verify the required parameter 'bagUsageId' is set
      if (bagUsageId === undefined || bagUsageId === null) {
        throw new Error("Missing the required parameter 'bagUsageId' when calling managementOrgBagUsageUpdate");
      }

      // verify the required parameter 'bagusageUpdateBagUsageParam' is set
      if (bagusageUpdateBagUsageParam === undefined || bagusageUpdateBagUsageParam === null) {
        throw new Error("Missing the required parameter 'bagusageUpdateBagUsageParam' when calling managementOrgBagUsageUpdate");
      }


      let pathParams = {
        'orgId': orgId,
        'bagUsageId': bagUsageId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;

      return this.apiClient.callApi(
        '/management/org/{orgId}/bag-usage/{bagUsageId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update bag usages
     * @param {Number} orgId org id
     * @param {Number} bagUsageId bagUsageId
     * @param {module:model/BagusageUpdateBagUsageParam} bagusageUpdateBagUsageParam Update bag Usage parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    managementOrgBagUsageUpdate(orgId, bagUsageId, bagusageUpdateBagUsageParam) {
      return this.managementOrgBagUsageUpdateWithHttpInfo(orgId, bagUsageId, bagusageUpdateBagUsageParam)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get bag usages
     * @param {Number} orgId org id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BagusageOutput} and HTTP response
     */
    managementOrgBagUsagesWithHttpInfo(orgId) {
      let postBody = null;

      // verify the required parameter 'orgId' is set
      if (orgId === undefined || orgId === null) {
        throw new Error("Missing the required parameter 'orgId' when calling managementOrgBagUsages");
      }


      let pathParams = {
        'orgId': orgId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = BagusageOutput;

      return this.apiClient.callApi(
        '/management/org/{orgId}/bag-usage', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get bag usages
     * @param {Number} orgId org id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BagusageOutput}
     */
    managementOrgBagUsages(orgId) {
      return this.managementOrgBagUsagesWithHttpInfo(orgId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
