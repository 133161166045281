import React from 'react';
import {Provider} from 'react-redux'

import { ConnectedRouter } from 'connected-react-router'

import { renderRoutes } from 'react-router-config';
import routes from '~/src/routes';
import store, {history} from './redux';

import {actionType} from "~/src/redux/action";
import ApiClient from "~/src/api-client/ApiClient";

// Set API URL
ApiClient.instance.basePath = process.env.REACT_APP_API_URL.replace(/\/+$/, '');

// init action
store.dispatch({
    type: actionType.INIT,
});

class App extends React.Component {
    render(){
        return (
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    {/* kick it all off with the root route */}
                    {renderRoutes(routes)}
                </ConnectedRouter>
            </Provider>
        );
    }
}

export default App;
