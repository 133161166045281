// Create and Update Form
import {Button, Drawer, Form, notification, Select} from "antd";
import React, {Component} from "react";
import {FormType} from "~/src/utils/form";
import ShopApi from "~/src/api-client/api/ManagementShopApi";

@Form.create()
class CUForm extends Component {
    static defaultProps = {
        formType: FormType.create,
        data: {

        }
    };

    state = {
        haveShopper: false,
    }

    create = (data) => {
        let record = null
        this.props.itemList.forEach((v) => {
            if(v.id == data.itemId) {
                record = v;
                if(record.availableOrgIds == null) {
                    record.availableOrgIds = [];
                }
                record.availableOrgIds.push(this.props.org.id);
            }
        });

        return new ShopApi().managementShopUpdateItem(record.id, record).then(() => {
            if (this.props.onSuccess) {
                this.props.onSuccess()
            }
            notification.success({message: `The item was successfully created`})
        }).catch((err) => {
            notification.warning({message: `Cannot create item - ${data.name}`, description: `${err}`, duration: 0});
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (err) {
                return;
            }

            this[this.props.formType](values)
        });
    }

    componentDidMount() {
        // Class list
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {user} = this.props.data;
        const {itemList} = this.props;

        return <Drawer
            title={this.props.title}
            width={720}
            onClose={this.props.onClose}
            visible={this.props.isOpen}
        >
            <Form layout="vertical" onSubmit={this.handleSubmit}>
                <Form.Item label="Item" hasFeedback colon={false}>
                    {getFieldDecorator('itemId', {rules: [{required: true}], initialValue: user ? user.id: null})(
                        <Select>
                            {
                                itemList.map((v, i) => {
                                    return <Select.Option key={i} value={v.id}>{v.name}</Select.Option>
                                })
                            }
                        </Select>
                    )}
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">Submit</Button>
                </Form.Item>
            </Form>
        </Drawer>
    }
}

export default CUForm;