import React, { Component } from 'react';
import './panel.scss'
// import SideNav from '~/src/components/SideNav'
import HomeButton from '~/src/components/HomeButton'
import {Layout, Popover, Icon, Avatar, Row, Col} from 'antd'

import bigwasterLogoImage from './bigwaster-logo.png'
import { connect } from 'react-redux'
import {logout} from "~/src/redux/action"
@connect(
    (state, ownProps) => {
        return {
            userDisplayName: null,
            role: state.user.role
        }
    },
    (dispatch, ownProps) => {
        return {
            onLogoutClick: () => {
                dispatch(logout())
            }
        }
    }
)
class Panel extends Component {
    state = {isNavExpanded: false, isUserMenuVisible: true};

    render() {
        const {isNavExpanded} = this.state;
        const {onLogoutClick, role} = this.props;

        return <div>
            { (role === 'admin' || role === 'helper') && <HomeButton/> }
            <div style={{transition: 'all 0.15s  ease-in-out'}}>
                <Layout.Header style={{height: '90px', background: '#14adbf', padding: '0 20px'}}>
                    <Row type="flex" align="middle">
                        <Col xs={0} sm={12} md={12} span={12}><img src={bigwasterLogoImage} style={{marginTop: '10px', height: '80px', width: 'auto'}}/></Col>
                        <Col xs={24} sm={12} md={12} span={12} style={{textAlign: 'right'}}>
                            <Popover
                                placement="bottomRight"
                                content={<a onClick={onLogoutClick}><Icon type="logout" /> Logout</a>}
                                trigger="click"
                            >
                                <span className='user'><Avatar className='avatar' shape="square" icon="user"/> {this.props.userDisplayName} <Icon type="down" /></span>
                            </Popover>
                        </Col>
                    </Row>

                </Layout.Header>
                {this.props.children}
            </div>

        </div>
    }

    // onNavToggle = (isExpended) => {
    //     this.setState({isNavExpanded: isExpended})
    // }
}

export default Panel;

export function withPanel(Component) {
    return (props) => {
        return <Panel>{<Component {...props} />}</Panel>
    }
}
