import React, {Component} from 'react'
import DataTable, {filterType} from "~/src/components/DataTable";
import {Button, Menu, Icon, Drawer, Form, Input, DatePicker, Select, notification} from 'antd'
import _ from 'lodash'

import ManagementUserApi from "~/src/api-client/api/ManagementUserApi"

import {FormType} from "~/src/utils/form"
import {OperationColumn} from '~/src/utils/data-table'

import ManagementHelperApi from "~/src/api-client/api/ManagementHelperApi"
import moment from 'moment'
import csv from "~/src/csv"
import CUForm from "./Form";
import {ManagementOrgBagUsageApi} from "~/src/api-client";
import ShopApi from "~/src/api-client/api/ManagementShopApi";
import ManagementOrgBagOrderApi from "~/src/api-client/api/ManagementOrgBagOrderApi";

class ManagementOrgOrderBag extends Component {
    state = {
        filteredValue: {},
        bagOrders: [],
        grouppedBagOrders: [],
        orgUsers: [],
        itemList: [],
        isOpenCreateDrawer: false,
        isOpenUpdateDrawer: false,
        modifyFormData: {}
    };

    static OrgUsers(orgId) {
        return new ManagementUserApi().managementUsers({org: orgId}).then(({users: allUsers}) => {
            let users = _(allUsers).filter((v) => v.role == 'user' || v.role == 'org').value();
            return users;
        })
    }

    static ShopItemList() {
        return new ShopApi().managementShopItems().then(({items}) => {
            return items;
        })
    }

    static BagOrders(orgId) {
        return new ManagementOrgBagOrderApi().managementOrgBagOrders(orgId).then(({bagOrder}) => {
            return bagOrder;
        })
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.constructor.ShopItemList().then((itemList) => {
            this.setState({itemList: _.map(itemList, item => item.name).sort((a,b) => a.localeCompare(b, undefined, {numeric:true}))})
        });

        this.constructor.OrgUsers(this.props.orgInfo.id).then((orgUsers) => {
            this.setState({orgUsers: orgUsers});
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const {itemList} = this.state;
        if (!_.isEqual(itemList, prevState.itemList)) {
            this.refreshData();
        }
    }

    handleOperationBarOnClick = ({item, key}) => {
        if (key == FormType.create) {
            this.setState({isOpenCreateDrawer: true})
        }

        if (key == csv.downloadType) {
            csv.download(this.state.grouppedBagOrders.map((v, k) => {

                v.date = moment(v.date).format('YYYY-MM-DD');
                return v
            }), _.concat(['Date', 'Class'], this.state.itemList, 'Delivered?'), _.concat(['date', 'username'], this.state.itemList, 'isDelivered'), `${this.props.orgInfo.name}-${moment().format('YYYY-MM-DD')}-bag-order`)
        }
    };

    handleRecordModifyOnClick = (record) => {
        this.setState({isOpenUpdateDrawer: true, modifyFormData: record})
    };

    handleCUFormOnClose = () => {
        this.setState({isOpenCreateDrawer: false, isOpenUpdateDrawer: false})
    };

    handleDataChange = () => {
        this.refreshData();
        this.setState({isOpenCreateDrawer: false, isOpenUpdateDrawer: false})
    };

    refreshData = () => {
        this.props.setOrgPageIsLoading(true);
        this.constructor.BagOrders(this.props.orgInfo.id).then((bagOrders) => {
            const grouppedBagOrders = _.chain(bagOrders)
                                        .groupBy(order => `${moment(order.date).format('YYYY-MM-DD')},${order.userId},${order.username}`)
                                        .map((order, string) => {
                                            const splittedString = string.split(',');
                                            let returnObj = { id: string, date: splittedString[0], userId: parseInt(splittedString[1]), username: splittedString[2], isDelivered: _.every(order, 'isDelivered') };
                                            _.forEach(this.state.itemList, item => {
                                                returnObj[item] = _.sumBy(_.filter(order, {'bagType': item}), 'quantity');
                                            });

                                            return returnObj;
                                        })
                                        .value();

            this.setState({bagOrders, grouppedBagOrders});
        }).catch((err) => {
            notification.warning({message: `Cannot refresh data`, description: `${err}`, duration: 0});
        }).finally(() => {
            this.props.setOrgPageIsLoading(false);
        })
    };

    handleDeleteRecord = (record) => {
        this.props.setOrgPageIsLoading(true);
        const targetBagOrders = _.filter(this.state.bagOrders, order => moment(order.date).format('YYYY-MM-DD') === record.date && order.userId === record.userId);
        return Promise.all(_.map(targetBagOrders, order => new ManagementOrgBagOrderApi().managementOrgBagOrderDelete(this.props.orgInfo.id, order.id)))
        // return new ManagementOrgBagOrderApi().managementOrgBagOrderDelete(this.props.orgInfo.id, record.id)
        .then(() => {
            return null
        }).then(() => {
            notification.success({message: `${record.date} was successfully deleted`})
        }).catch((err) => {
            notification.warning({message: `Cannot delete ${record.date}`, description: `${err}`, duration: 0});
        }).finally(() => {
            this.props.setOrgPageIsLoading(false);
            this.refreshData();
        })
    };

    render() {
        const {modifyFormData, isOpenCreateDrawer, isOpenUpdateDrawer, itemList, filteredValue, orgUsers, bagOrders, grouppedBagOrders} = this.state;
        const {orgInfo} = this.props;
        let columns = [{
            title: 'Date',
            dataIndex: 'date',
            render: (date, record) => moment(date).format('YYYY-MM-DD'),
            sorter: (a, b) => new Date(a.date) - new Date(b.date),
        },
            {
                title: 'Class',
                dataIndex: 'username',
                sorter: (a, b) => a.localeCompare(b),
                render: (username) => username || '-'
            },
            // {
            //     title: 'Bag Type',
            //     dataIndex: 'bagType',
            //     sorter: (a, b) => a.bagType.localeCompare(b.bagType),
            // }, {
            //     title: 'Qty',
            //     dataIndex: 'quantity',
            //     sorter: (a, b) => a > b,
            // }, 
        ];

        _.forEach(itemList, item => {
            columns.push({
                title: item,
                dataIndex: item,
                render: (data, record) => data || 0
            });
        })

        columns.push({
            title: 'Delivered?',
            dataIndex: 'isDelivered',
            align: 'center',
            render: (isDelivered) => isDelivered ? 'Yes' : 'No'
        }, OperationColumn(null, this.handleRecordModifyOnClick, this.handleDeleteRecord));

        return <div>
            <DataTable operationBarComponent={<OperationBarComponent onClick={this.handleOperationBarOnClick}/>}
                       columns={columns}
                       data={grouppedBagOrders}
                       pageSize={50}
                       hideFilter={true}
                       rowKey="id"
            />

            {/* Create Form */}
            {isOpenCreateDrawer &&
            <CUForm
                title={'Create a new order'}
                isOpen={isOpenCreateDrawer}
                formType={FormType.create}
                org={orgInfo}
                orgUsers={orgUsers}
                itemList={itemList}
                onClose={this.handleCUFormOnClose}
                onSuccess={this.handleDataChange}
            />}

            {/* Update Form */}
            {isOpenUpdateDrawer &&
            <CUForm
                title={'Modify a order'}
                data={modifyFormData}
                bagOrders={bagOrders}
                org={orgInfo}
                orgUsers={orgUsers}
                itemList={itemList}
                isOpen={isOpenUpdateDrawer}
                formType={FormType.modify}
                onClose={this.handleCUFormOnClose}
                onSuccess={this.handleDataChange}
            />}

        </div>
    }
}

const OperationBarComponent = (props) => {
    return <Menu
        mode="horizontal"
        selectable={false}
        onClick={props.onClick}
    >
        <Menu.Item key={FormType.create}>
            <Icon type="plus"/>Create
        </Menu.Item>
        <Menu.Item key={csv.downloadType}>
            <Icon type="download"/>Download
        </Menu.Item>
    </Menu>
};

export default ManagementOrgOrderBag;
