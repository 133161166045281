import React, {Component} from "react";
import {Table, Layout, Menu, Icon} from 'antd';

import FilterBlock, {type as filterType} from "~/src/components/FilterBlock";
import './DataTable.scss'

class DataTable extends Component {
    render() {
        const {Sider, Content} = Layout;
        const {operationBarComponent, data, rowKey, columns, pageSize, onFilterChange, hideFilter} = this.props;
        return (
            <Layout className="data-table ant-layout ant-layout-has-sider">
                {hideFilter ? '' : <Sider theme="light" width="200" style={{
                    padding: 24, borderRight: "1px solid #ccc"
                }}>
                    <FilterBlock columns={columns} data={data}
                                 onChange={onFilterChange}/>
                </Sider>}

                <Content style={{overflowX: 'inherit'}}>
                    <div className="operation-bar">
                        {
                            operationBarComponent ? operationBarComponent : ''
                        }
                    </div>
                    <Table
                        rowKey={rowKey}
                        dataSource={data}
                        columns={ columns}
                        pagination={{pageSize: pageSize ? pageSize : 50}}
                        style={{background: '#fff'}}
                    />
                </Content>
                {/*<Content><Table columns={columns} dataSource={data} onChange={this.handleChange} /></Content>*/}
                {/*<Drawer title="Create a new account" width={720} visible={true}/>*/}
            </Layout>
        )
    }
}

export default DataTable;
export {filterType};