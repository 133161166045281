import React, {Component} from "react";
import {Card, Layout, Menu, Icon, Row, Col, Pagination, Button, Dropdown} from 'antd';

import FilterBlock, {type as filterType} from "~/src/components/FilterBlock";
import './DataCard.scss'
import _ from 'lodash'

class DataTable extends Component {
    state = {
        currentPage: 1,
        pageSize: 24,
    }

    currentPage = () =>{
        const {currentPage} = this.state;
        return this.page(currentPage);
    };

    page = (p) =>{
        const {pageSize} = this.state;
        const {data} = this.props;
        return p > Math.ceil(data.length/pageSize) ? Math.ceil(data.length/pageSize) : p
    };

    render() {
        const {data, columns} = this.props;
        // {
        //     data: [
        //         {
        //             cover: '',
        //             body: '',
        //         }
        //     ],
        //     totalCard: 10,
        //     pageSize: 50,
        //     currentPage: 1,
        //     onPageChange: '',
        //     onFilterChange: '',
        //     onPaginationChange: '',
        //     filterColumns: [],
        //     operationBarComponent: [],
        //
        // }
        const {Sider, Content} = Layout;

        return (
            <Layout className="data-card ant-layout ant-layout-has-sider">
                <Sider theme="light" width="200" style={{
                    padding: 24, borderRight: "1px solid #ccc"
                }}>
                    <FilterBlock columns={this.props.columns}
                                 onChange={this.props.onFilterChange}/>
                </Sider>
                <Content style={{overflowX: 'inherit'}}>
                    <div className="operation-bar">
                        {this.props.operationBarComponent ? this.props.operationBarComponent : <Menu
                            mode="horizontal"
                        >
                            <Menu.Item key="menu" disabled>
                                <Icon type="menu"/>Empty Menu
                            </Menu.Item>
                        </Menu>}
                    </div>
                    <Row gutter={0} style={{margin: 5}} type="flex" justify="space-between" align="middle">
                        {data.map((record, i) => {
                            // skip
                            if(this.currentPage() * this.state.pageSize <= i ||
                                (this.currentPage() - 1) * this.state.pageSize > i){
                                return
                            }

                            return <Col span={8} xxl={6} xs={24} xl={8} md={8} key={i}>
                                <Card style={{margin: 5}}
                                      cover={
                                          <video controls poster={this.props.columns[0].render(record)+ ".jpg"} preload="none">
                                              <source src={this.props.columns[0].render(record)} />
                                                  Your browser does not support the video tag.
                                              </video>
                                      }
                                >
                                    <div style={{margin: '10px 0'}}>
                                    {
                                        columns.map((column, x) => {
                                            if (x == 0) {
                                                return
                                            }

                                            if(column.render) {
                                                return <div key={x}>{column.render(record)}</div>
                                            } else {
                                                return <div  key={x} style={{marginTop: '5px'}}><h5>{`${column.title}`}</h5>
                                                    {_.get(record, `${column.dataIndex}`)}</div>
                                            }

                                        })
                                    }
                                    </div>
                                </Card>
                            </Col>;
                        })}
                    </Row>
                    <Pagination current={this.currentPage()} total={data.length} pageSize={this.state.pageSize} onChange={(page)=>{
                        this.setState({currentPage: this.page(page)});
                        if(this.props.onPaginationChange){
                            this.props.onPaginationChange();
                        }
                    }}/>
                </Content>
                {/*<Content><Table columns={columns} dataSource={data} onChange={this.handleChange} /></Content>*/}
                {/*<Drawer title="Create a new account" width={720} visible={true}/>*/}
            </Layout>
        )
    }
}

export default DataTable;
export {filterType};