import React, {Component} from 'react'
import {connect} from "react-redux";
import DataCard, {filterType} from "~/src/components/DataCard";
import {Button, Menu, Icon, Dropdown, notification} from 'antd'
import _ from 'lodash'
import {setOrgPageIsLoading} from '~/src/redux/action'

import ManagementOrgApi from "~/src/api-client/api/ManagementOrgApi"

import ManagementCompetitionApi from "~/src/api-client/api/ManagementCompetitionApi";

import {fetchCurrentOrg} from "~/src/redux/action";
import moment from "moment";

class OrgManagementCompetition extends Component {
    state = {
        filtered: {
            status: ['pending'],
            class: []
        },
        isOpenCreateDrawer: false,
        isOpenUpdateDrawer: false,
        submissions: [],
        filteredSubmissions: [],
    };

    static submissionStatus = {
        approved: 'Approved',
        rejected: 'Rejected',
        pending: 'Pending',
    };

    static submissionStatusValue = {
        Approved: 'approved',
        Rejected: 'rejected',
        Pending: 'pending',
    };

    static getDerivedStateFromProps(props, state) {
        // get result
        if (!state.result) {
            console.log('props.submissions', props.submissions)
            return {
                result: {
                    submissions: props.submissions,
                }
            };
        }
        return null;
    }

    static Org(orgId) {
        return new ManagementOrgApi().managementOrg(orgId).then((data) => {
            return data.org
        })
    }

    static SubmissionList(orgId) {
        return new ManagementCompetitionApi().managementCompetitionSubmissions(1, {orgId: orgId}).then(({submissions}) => {
            submissions.forEach((v) => {
                console.log(this.submissionStatus);
                if (v.status == null) {
                    v.status = 'pending'
                }
            })
            return submissions;
        })
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.refreshData();
    }

    refreshData = () => {
        this.props.setOrgPageIsLoading(true);
        this.constructor.SubmissionList(this.props.orgInfo.id).then((submissions) => {
            this.setState({submissions: submissions});
            this.setState({filteredSubmissions: submissions});
            this.filterData()
        }).catch((err) => {
            notification.warning({message: `Cannot refresh data`, description: `${err}`, duration: 0});
        }).finally(()=>{
            this.props.setOrgPageIsLoading(false);
        })
    };

    handleUpdateRecord = (record) => {
        this.props.setOrgPageIsLoading(true);
        return new ManagementCompetitionApi().managementCompetitionUpdateSubmission(1, record.submissionId, record).then(() => {
            notification.success({message: `${record.user.name} ${record.applicant} was successfully updated`})
        }).catch((err) => {
            notification.warning({message: `Cannot delete ${record.name}`, description: `${err}`, duration: 0});
        }).finally(() => {
            this.refreshData();
            this.props.setOrgPageIsLoading(false);
        })
    };

    filterData() {
        const {submissions} = this.state;
        if (!submissions) {
            return
        }

        let a = _.filter(submissions, (o) => {
            return (
                this.state.filtered.status.length > 0 ? _.includes(this.state.filtered.status, o.status) : true
            )
        });

        let b = _.filter(a, (o) => {
            return (
                this.state.filtered.class.length > 0 ? _.includes(this.state.filtered.class, o.user.name) : true
            )
        });

        this.setState(
            {
                filteredSubmissions: b
            }
        )
    }

    render() {
        const {submissions, filteredSubmissions} = this.state;

        let menuBtn = {
            pending: <span><Icon type="minus"/> Pending</span>,
            rejected: <span><Icon type="close" style={{color: 'red'}}/> Rejected</span>,
            approved: <span><Icon type="check" style={{color: 'green'}}/> Approved</span>,
        }

        let statusColor = {
            pending: '#efefef',
            rejected: '#ffb6b0',
            approved: '#acffd7',
        }

        let columns = [
            {
                title: 'cover',
                dataIndex: 'filePath',
                render: (record) => {
                    // return process.env.API_URL+ '/' + record.filePath
                    return process.env.REACT_APP_VIDEO_URL + record.filePath
                }
            },
            {
                title: 'Operation',
                dataIndex: '',
                render: (record) => {
                    return <Dropdown overlay={<Menu onClick={({key}) => {
                        console.log(record)
                        record.status = key;

                        this.handleUpdateRecord(record)
                    }}>
                        <Menu.Item key="pending">{menuBtn.pending}</Menu.Item>
                        <Menu.Item key="rejected">{menuBtn.rejected}</Menu.Item>
                        <Menu.Item key="approved">{menuBtn.approved}</Menu.Item>
                    </Menu>} trigger={['click']} placement="topLeft">
                        <Button size="small" style={{background: statusColor[record.status]}}>
                            {menuBtn[record.status]}
                            <Icon type="down"/>
                        </Button>
                    </Dropdown>
                }
            },
            {
                title: 'Name',
                dataIndex: 'applicant',
            }, {
                title: 'Class',
                dataIndex: 'user.name',
                filter: {
                    type: filterType.Checkbox,
                    options: _(submissions).map('user.name').uniq().value(),
                    defaultValue: this.state.filtered.class,
                    onChange: (v) => {
                        this.setState({filtered: Object.assign(this.state.filtered, {'class': v})})
                        this.filterData()
                    }
                },
            }, {
                title: 'Submission Date',
                dataIndex: 'createdAt',
                // borrow dataIndex createdAt for status filter
                filter: {
                    title: 'Status',
                    type: filterType.Checkbox,
                    options: Object.values(this.constructor.submissionStatus),
                    defaultValue: this.state.filtered.status.map((v) => this.constructor.submissionStatus[v]),
                    onChange: (v) => {
                        let status = [];
                        Object.keys(this.constructor.submissionStatus).forEach((x) => {
                            v.forEach((y) => {
                                if (this.constructor.submissionStatus[x] == y) {
                                    status.push(x);
                                }
                            })
                        });

                        this.setState({filtered: Object.assign(this.state.filtered, {'status': status})})
                        this.filterData()
                    }
                },
                render: (record) => {
                    return moment(record.createdAt).toLocaleString()
                }
            }];

        return <div>
            {/*<TitleBar title={`${result.org.name}`}/>*/}
            {/*<PageMenu selected={'competition'}/>*/}
            <DataCard columns={columns} data={filteredSubmissions} pageSize={50}/>
        </div>
    }
}

export default OrgManagementCompetition;
