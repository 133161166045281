import React, { useState } from 'react'
import PageMenu from "~/src/components/PageMenu";
import {Menu as AntdMenu, Icon} from 'antd'
import {withRouter} from "react-router";

const {SubMenu} = AntdMenu;

export const AdminMenu = (props) => {
    let orgId = props.match.params.orgId;
    const [selected, setSelected] = useState('student');

    return <PageMenu selected={selected} {...props} onClick={({item, key, keyPath}) => {
            setSelected(key);
            props.history.push(item.props.path)
        }}>
            <AntdMenu.Item key="student" path={{pathname: `/management/org/${orgId}/user`}}>
                <Icon type="smile"/>Student
            </AntdMenu.Item>
            <AntdMenu.Item key="competition" path={{pathname: `/management/org/${orgId}/competition`}}>
                <Icon type="picture"/>Competition
            </AntdMenu.Item>
            <SubMenu key="shop" title={<span><Icon type="shop"/>Shop</span>}>
                <AntdMenu.Item key="shop-item" path={{pathname: `/management/org/${orgId}/shop/item`}}><Icon
                    type="gift"/>Item</AntdMenu.Item>
                <AntdMenu.Item key="shop-order" path={{pathname: `/management/org/${orgId}/shop/order`}}><Icon
                    type="file-text"/>Order</AntdMenu.Item>
            </SubMenu>
            <AntdMenu.Item key="bag-order" path={{pathname: `/management/org/${orgId}/bag-order`}}>
                <Icon type="gift"/>Bag Order
            </AntdMenu.Item>
            <SubMenu key="usage" title={<span><Icon type="profile"/>Usage</span>}>
                <AntdMenu.Item key="waste-usage"
                           path={{pathname: `/management/org/${orgId}/usage/waste`}}><Icon type="delete"/>Waste
                    Usage</AntdMenu.Item>
                <AntdMenu.Item key="bag-usage" path={{pathname: `/management/org/${orgId}/usage/bag`}}><Icon
                    type="gift"/>Bag Usage</AntdMenu.Item>
            </SubMenu>
            <AntdMenu.Item key="refunded-bag" path={{pathname: `/management/org/${orgId}/refunded-bag`}}>
                <Icon type="file"/>Refunded Bag
            </AntdMenu.Item>
            <AntdMenu.Item key="statement" path={{pathname: `/management/org/${orgId}/statement`}}>
                <Icon type="pie-chart"/>Statement
            </AntdMenu.Item>
            <AntdMenu.Item key="school-admin" path={{pathname: `/management/org/${orgId}/admin-user`}}>
                <Icon type="smile"/>School Admin
            </AntdMenu.Item>
            {/*<AntdMenu.Item key="helper-schedule"*/}
            {/*           path={{pathname: `/management/org/${orgId}/helper-schedule`}}>*/}
            {/*    <Icon type="calendar"/>Helper Schedule*/}
            {/*</AntdMenu.Item>*/}
        </PageMenu>
};


export const HelperMenu = (props) => {
    let orgId = props.match.params.orgId;
    const [selected, setSelected] = useState('waste-usage');

    return <PageMenu selected={selected} {...props} onClick={({item, key, keyPath}) => {
        setSelected(key);
        props.history.push(item.props.path)
    }}>
        <SubMenu key="usage" title={<span><Icon type="profile"/>Usage</span>}>
            <AntdMenu.Item key="waste-usage"
                           path={{pathname: `/management/org/${orgId}/usage/waste`}}><Icon type="delete"/>Waste
                Usage</AntdMenu.Item>
            <AntdMenu.Item key="bag-usage" path={{pathname: `/management/org/${orgId}/usage/bag`}}><Icon
                type="gift"/>Bag Usage</AntdMenu.Item>
        </SubMenu>
    </PageMenu>
};


export const SchoolAdminMenu = (props) => {
    let orgId = props.match.params.orgId;
    const [selected, setSelected] = useState('bag-order');

    return <PageMenu selected={selected} {...props} onClick={({item, key, keyPath}) => {
        setSelected(key);
        props.history.push(item.props.path)
    }}>
        <AntdMenu.Item key="bag-order" path={{pathname: `/management/org/${orgId}/bag-order`}}>
            <Icon type="gift"/>Bag Order
        </AntdMenu.Item>
        <AntdMenu.Item key="statement" path={{pathname: `/management/org/${orgId}/statement`}}>
            <Icon type="pie-chart"/>Statement
        </AntdMenu.Item>
    </PageMenu>
};

export default {
    Admin: withRouter(AdminMenu),
    SchoolAdmin: withRouter(SchoolAdminMenu),
    Helper:withRouter(HelperMenu),
}