import _ from 'lodash'

export default {
    download: (rowObjects, header, fields, filename) => {
        let csvContent = 'data:text/csv;charset=utf-8,';

        if(header){
            csvContent += header.join(',') + '\r\n';
        }

        rowObjects.forEach(function (obj) {
            let row = [];

            fields.forEach((field)=>{
                row.push(_.get(obj, field) ? _.get(obj, field): '');
            });

            csvContent += row.join(',') + '\r\n';
        });

        let encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', filename + '.csv');
        document.body.appendChild(link); // Required for FF

        link.click();
    },
    downloadType: 'csv'
};