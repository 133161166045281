import React, {Component} from 'react'
import DataTable, {filterType} from "~/src/components/DataTable";
import {Button, Menu, Icon, Drawer, Form, Input, DatePicker, Select, notification} from 'antd'
import _ from 'lodash'

import ManagementUserApi from "~/src/api-client/api/ManagementUserApi"

import {FormType} from "~/src/utils/form"
import {OperationColumn} from '~/src/utils/data-table'

import ManagementHelperApi from "~/src/api-client/api/ManagementHelperApi"
import moment from 'moment'
import csv from "~/src/csv"
import CUForm from "./Form";
import {ManagementOrgBagUsageApi} from "~/src/api-client";
import ShopApi from "~/src/api-client/api/ManagementShopApi";
//
// @compose(
//     connect((state, props) => {
//             return {
//                 org: state.currentOrg,
//             }
//         }
//     ),
//     withPanel,
//     wrapper({selectedMenuItem: 'waste-usage'}),
//     newInitFetch([
//         {
//             name: 'wasteRecords',
//             fn: (component, router) => {
//                 return component.WasteRecords(router.query.orgId)
//             }
//         },
//         {
//             name: 'orgUsers',
//             fn: (component, router) => {
//                 return component.OrgUsers(router.query.orgId)
//             }
//         }
//     ])
// )
class OrgManagementBagUsage extends Component {
    state = {
        filteredValue: {},
        bagUsages: [],
        grouppedBagUsages: [],
        orgUsers:[],
        itemList: [],
        isOpenCreateDrawer: false,
        isOpenUpdateDrawer: false,
        modifyFormData: {}
    };

    static OrgUsers(orgId) {
        return new ManagementUserApi().managementUsers({org: orgId}).then(({users: allUsers}) => {
            let users = _(allUsers).filter((v)=> v.role == 'user' || v.role == 'org').value();
            return users;
        })
    }

    static ShopItemList() {
        return new ShopApi().managementShopItems().then(({items}) => {
            return items;
        })
    }

    static BagUsages(orgId) {
        return new ManagementOrgBagUsageApi().managementOrgBagUsages(orgId).then(({bagUsage}) => {
            return bagUsage;
        })
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.constructor.ShopItemList().then((itemList)=>{
            this.setState({itemList: _.map(itemList, item => item.name).sort((a,b) => a.localeCompare(b, undefined, {numeric:true}))})
        });

        this.constructor.OrgUsers(this.props.orgInfo.id).then((orgUsers)=>{
            this.setState({orgUsers: orgUsers});
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const {itemList} = this.state;
        if (!_.isEqual(itemList, prevState.itemList)) {
            this.refreshData();
        }
    }

    handleOperationBarOnClick = ({item, key}) => {
        if (key == FormType.create) {
            this.setState({isOpenCreateDrawer: true})
        }

        if (key == csv.downloadType) {
            csv.download(this.state.grouppedBagUsages.map((v, k)=>{

                v.date = moment(v.date).format('YYYY-MM-DD');
                return v
            }), _.concat(['Date'], this.state.itemList), _.concat(['date'], this.state.itemList), `${this.props.orgInfo.name}-${moment().format('YYYY-MM-DD')}-bag-usage`)
        }
    };

    handleRecordModifyOnClick = (record) => {
        this.setState({isOpenUpdateDrawer: true, modifyFormData: record})
    };

    handleCUFormOnClose = () => {
        this.setState({isOpenCreateDrawer: false, isOpenUpdateDrawer: false})
    };

    handleDataChange = () => {
        this.refreshData();
        this.setState({isOpenCreateDrawer: false, isOpenUpdateDrawer: false})
    };

    refreshData = () => {
        this.props.setOrgPageIsLoading(true);
        this.constructor.BagUsages(this.props.orgInfo.id).then((bagUsages) => {
            const grouppedBagUsages = _.chain(bagUsages)
                                        .groupBy(usage => moment(usage.date).format('YYYY-MM-DD'))
                                        .map((usage, date) => {
                                            let returnObj = { date };
                                            _.forEach(this.state.itemList, item => {
                                                returnObj[item] = _.sumBy(_.filter(usage, {'bagType': item}), 'quantity');
                                            });

                                            return returnObj;
                                        })
                                        .value();

            this.setState({bagUsages, grouppedBagUsages});
        }).catch((err) => {
            notification.warning({message: `Cannot refresh data`, description: `${err}`, duration: 0});
        }).finally(()=>{
            this.props.setOrgPageIsLoading(false);
        })
    };

    handleDeleteRecord = (record) => {
        this.props.setOrgPageIsLoading(true);
        const targetBagUsages = _.filter(this.state.bagUsages, order => moment(order.date).format('YYYY-MM-DD') === record.date);
        return Promise.all(_.map(targetBagUsages, order => new ManagementOrgBagUsageApi().managementOrgBagUsageDelete(this.props.orgInfo.id, order.id)))
        // return new ManagementOrgBagUsageApi().managementOrgBagUsageDelete(this.props.orgInfo.id, record.id)
        .then(() => {
            return null
        }).then(() => {
            notification.success({message: `${record.name} was successfully deleted`})
        }).catch((err) => {
            notification.warning({message: `Cannot delete ${record.date}`, description: `${err}`, duration: 0});
        }).finally(() => {
            this.props.setOrgPageIsLoading(false);
            this.refreshData();
        })
    };

    render() {
        const {modifyFormData, isOpenCreateDrawer, isOpenUpdateDrawer, itemList, filteredValue, orgUsers, bagUsages, grouppedBagUsages} = this.state;
        const {orgInfo} = this.props;
        let columns = [{
            title: 'Date',
            dataIndex: 'date',
            render: (date, record) => moment(date).format('YYYY-MM-DD'),
            sorter: (a, b) => new Date(a.date) - new Date(b.date),
        }];

        _.forEach(itemList, item => {
            columns.push({
                title: item,
                dataIndex: item,
                render: (data, record) => data || 0
            });
        });

        columns.push(OperationColumn(null, this.handleRecordModifyOnClick, this.handleDeleteRecord));

        return <div>
            <DataTable operationBarComponent={<OperationBarComponent onClick={this.handleOperationBarOnClick}/>}
                       columns={columns}
                       data={grouppedBagUsages}
                       pageSize={50}
                       hideFilter={true}
                       rowKey="date"
            />

            {/* Create Form */}
            {isOpenCreateDrawer &&
            <CUForm
                title={'Create a new order'}
                isOpen={isOpenCreateDrawer}
                formType={FormType.create}
                org={orgInfo}
                orgUsers={orgUsers}
                itemList={itemList}
                onClose={this.handleCUFormOnClose}
                onSuccess={this.handleDataChange}
            />}

            {/* Update Form */}
            {isOpenUpdateDrawer &&
            <CUForm
                title={'Modify a order'}
                data={modifyFormData}
                bagUsages={bagUsages}
                org={orgInfo}
                orgUsers={orgUsers}
                itemList={itemList}
                isOpen={isOpenUpdateDrawer}
                formType={FormType.modify}
                onClose={this.handleCUFormOnClose}
                onSuccess={this.handleDataChange}
            />}

        </div>
    }
}

const OperationBarComponent = (props) => {
    return <Menu
        mode="horizontal"
        selectable={false}
        onClick={props.onClick}
    >
        <Menu.Item key={FormType.create}>
            <Icon type="plus"/>Create
        </Menu.Item>
        <Menu.Item key={csv.downloadType}>
            <Icon type="download"/>Download
        </Menu.Item>
    </Menu>
};

export default OrgManagementBagUsage;
