import React, {Component} from 'react'

import {Button, DatePicker, Row, Col, Icon, notification} from 'antd'
import _ from 'lodash'

import imgBigwasterLogo from './bigwaster-logo.png'
import imgReconnectLogo from './logo.jpg'
import imgEcafLogo from './ecaf.jpg'
import moment from 'moment'
import ReactEcharts from "echarts-for-react"
import './statement.scss'

import ShopApi from "~/src/api-client/api/ManagementShopApi";
import ManagementHelperApi from "~/src/api-client/api/ManagementHelperApi";
import {ManagementOrgBagUsageApi} from "~/src/api-client";

const {MonthPicker} = DatePicker;
//
// @compose(
//     connect((state, props) => {
//             return {
//                 org: state.currentOrg,
//             }
//         }
//     ),
//     withPanel,
//     wrapper({selectedMenuItem: 'statement'}),
//     newInitFetch([
//         {
//             name: 'wasteRecords',
//             fn: (component, router) => {
//                 return component.WasteRecords(router.query.orgId)
//             }
//         },
//         {
//             name: 'orders',
//             fn: (component, router) => {
//                 return component.OrderList(router.query.orgId)
//             }
//         },
//     ])
// )
class OrgManagementStatement extends Component {
    state = {
        selectedMonth: null,
        wasteRecords: [],
        bagList:[],
    }

    static WasteRecords(orgId) {
        return new ManagementHelperApi().managementHelperWasteRecords({orgId: orgId}).then(({records}) => {
            return _(records)
                .groupBy((v) => {
                    return moment(v.date).format("YYYY-MM")
                }).value()
        })
    }

    static ShopItemList() {
        return new ShopApi().managementShopItems().then(({items}) => {
            return items;
        })
    }

    static BagUsage(orgId) {
        return new ManagementOrgBagUsageApi().managementOrgBagUsages(orgId).then(({bagUsage}) => {
            return _(bagUsage)
                .groupBy((v) => {
                    return moment(v.date).format("YYYY-MM") + '_' + v.bagType
                })
                .map((objs, key) => {
                        const keys = key.split('_');
                        const date = keys[0];
                        const bagType = keys[1];

                        let record = {
                            date: moment(date).format("YYYY-MM"),
                            bagType: bagType,
                            quantity: _.sumBy(objs, 'quantity'),
                            subTotalAmount: 0,
                            name: bagType,
                        };

                        return record
                    }
                )
                .sort((a,b) => a.bagType.localeCompare(b.bagType, undefined, {numeric:true}))
                .groupBy((v) => {
                    return moment(v.date).format("YYYY-MM")
                })
                .value();
        })
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.constructor.ShopItemList().then((bagList)=>{
            this.setState({bagList: bagList})
            this.refreshData()
        })
    }

    refreshData = () => {
        this.props.setOrgPageIsLoading(true);
        this.constructor.WasteRecords(this.props.orgInfo.id).then((wasteRecords) => {
            this.setState({wasteRecords: wasteRecords});
        }).catch((err) => {
            notification.warning({message: `Cannot refresh data`, description: `${err}`, duration: 0});
        });

        this.constructor.BagUsage(this.props.orgInfo.id).then((bagUsages) => {
            console.log(bagUsages)
            const clonedBagUsages = _.cloneDeep(bagUsages);
            _.forEach(clonedBagUsages, ((v, k)=>{
                v.forEach((vv, i)=>{
                    // console.log("ccc",this.state.bagList[_.findIndex(this.state.bagList, { name: vv.bagType})])
                    // console.log(_.findIndex(this.state.bagList, { name: vv.bagType}), vv.bagType)
                    vv.unitPrice = this.state.bagList[_.findIndex(this.state.bagList, { name: vv.bagType})].price
                    // console.log(":vv.unitPrice", vv.unitPrice)
                    vv.subTotalAmount = vv.quantity * vv.unitPrice;
                    v[i] = vv;
                    // console.log(v)
                })

            }));

            this.setState({bagUsages: clonedBagUsages});
        }).catch((err) => {
            notification.warning({message: `Cannot refresh data`, description: `${err}`, duration: 0});
        }).finally(() => {
            this.props.setOrgPageIsLoading(false);
        });
    };

    chartOptions(currentMonthOrders) {
        return {
            title: {
                text: 'Percentage Of Plastic Bag Usages',
                subtext: '膠袋使用百分比',
                x: 'center',
                textStyle: {
                    fontSize: 14, fontStyle: 'normal', color: '#000',
                    fontWeight: 'bold'
                },
                subtextStyle: {
                    fontSize: 14, fontStyle: 'normal', color: '#000',
                    fontWeight: 'bold'
                },
            },
            grid: {
                width: '20%',
                height: '20%',
            },
            series: [
                {
                    name: '膠袋',
                    type: 'pie',
                    radius: 85,
                    label: {
                        normal: {
                            show: true,
                            formatter: '{b} - {d}%',
                        },
                    },
                    data: currentMonthOrders.map((v, i) => {
                        return {
                            name: v.name,
                            value: v.quantity,
                        }
                    }),
                    itemStyle: {
                        emphasis: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        };
    }

    historyChartOptions(bagRecords, currentMonth) {
        let keys = Object.keys(bagRecords).sort().filter((v) => {
            //console.log(v, currentMonth, v > currentMonth)
            if (v > currentMonth) {
                return false
            }

            return true
        });

        let startDate = moment(keys.length > 12 ? keys[keys.length - 12] : keys[0]);
        let dates = [];
        for (var i = 0; i < 12; i++) {
            dates.push(moment(startDate).add(i, 'month').format("YYYY-MM"));
        }

        let data = [];
        dates.forEach((v) => {
            if (!bagRecords[v] || keys.indexOf(v) == -1) {
                data.push(null);
                return
            }

            data.push(bagRecords[v].reduce((accumulate, vv) => {
                return accumulate + vv.quantity
            }, 0))
        });

        return {
            title: {
                text: 'History Of Plastic Bag Usages',
                subtext: '過往膠袋使用紀錄',
                x: 'center',
                textStyle: {
                    fontSize: 14, fontStyle: 'normal', color: '#000',
                    fontWeight: 'bold'
                },
                subtextStyle: {
                    fontSize: 14, fontStyle: 'normal', color: '#000',
                    fontWeight: 'bold'
                },
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                left: 60,
                top: 50,
                right: 0,
                bottom: 20
            },
            xAxis: {
                type: 'category',
                data: dates
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    formatter: '{value} 個'
                },
            },
            series: [{
                data: data,
                type: 'bar',
                color: 'rgb(247,119,36)',
                label: {
                    normal: {
                        show: true,
                        position: 'top'
                    }
                },
            }]
        };
    }

    handleOnMonthPickerOnChange = (date) => {
        this.setState({selectedMonth: date})

    };

    render() {
        const {bagUsages, wasteRecords, selectedMonth} = this.state;
        const {orgInfo} = this.props;

        const selectedMonthKey = selectedMonth ? selectedMonth.format('YYYY-MM') : '';
        //console.log(selectedMonthKey, orders,  this.state);
        if (!bagUsages) {
            return <div></div>
        }

        const selectedLastMonthKey = selectedMonth ? moment(selectedMonth).add('month', -1).format('YYYY-MM') : '';
        const currentMonthOrders = bagUsages[selectedMonthKey];
        const currentMonthWasteUsage = wasteRecords[selectedMonthKey] ? wasteRecords[selectedMonthKey].reduce((r, obj) => {
                r.metal += obj.metal;
                r.paper += obj.paper;
                r.waste += obj.waste;
                r.plastic += obj.plastic;
                return r
            }, {
                date: selectedMonthKey,
                metal: 0,
                plastic: 0,
                paper: 0,
                waste: 0
            }
        ) : null;

        const lastMonthWasteUsage = wasteRecords[selectedLastMonthKey] ? wasteRecords[selectedLastMonthKey].reduce((r, obj) => {
                r.metal += obj.metal;
                r.paper += obj.paper;
                r.waste += obj.waste;
                r.plastic += obj.plastic;
                return r
            }, {
                date: selectedLastMonthKey,
                metal: 0,
                plastic: 0,
                paper: 0,
                waste: 0
            }
        ) : null;
        const totalAmount = _(currentMonthOrders).sumBy('subTotalAmount').toFixed(2);

        return <div id="print-area-wrap" style={{margin: '0 auto', padding: 20}}>
            <Row style={{padding: '10px 0'}}>
                <Col span={12}>
                    <MonthPicker onChange={this.handleOnMonthPickerOnChange} size={'large'} value={selectedMonth}
                                 placeholder="Select Month"/>
                </Col>
                <Col span={12} style={{textAlign: 'right'}}>

                    {currentMonthOrders && <Button size={'large'} onClick={() => {
                        window.print()
                    }}><Icon type="printer"/></Button>}

                </Col>
            </Row>
            {currentMonthOrders ?
                <div id="print-area" style={{border: '1px solid #000', padding: 10, background: '#fff'}}>
                    <Row style={{padding: 10}}>
                        <Col span={24}>
                            <div>
                                <img src={imgBigwasterLogo} style={{maxHeight: 110, width: 'auto'}}/>
                                <img src={imgEcafLogo} style={{maxHeight: 110, width: 'auto'}}/>
                                <img src={imgReconnectLogo} style={{maxHeight: 110, width: 'auto'}}/>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{padding: 10}}>
                        <Col span={24}>
                            <h2>Waste Reduction Statement 減廢月結單</h2>
                        </Col>
                    </Row>
                    <Row style={{padding: 10}}>
                        <Col span={24}>
                            <div>Issue Date 發出日期: {moment().format("YYYY-MM-DD")}</div>
                        </Col>
                    </Row>
                    <Row style={{padding: 10}}>
                        <Col span={8}>
                            <div style={{border: '1px solid #000', padding: 10,}}>
                                {orgInfo.name}
                                <pre>
                                {orgInfo.address}
                            </pre>
                            </div>
                        </Col>
                        <Col span={8} offset={8}>
                            <div style={{border: '1px solid #000', padding: 10,}}><b>Total Amount 應繳總金額: <br/><span
                                style={{fontSize: '2em'}}>${totalAmount}</span></b></div>
                            <div style={{border: '1px solid #000', padding: 10,}}>From
                                由: {moment(selectedMonth).startOf('month').format('YYYY-MM-DD')}<br/>To 至:
                                {moment(selectedMonth).endOf('month').format('YYYY-MM-DD')}
                            </div>
                        </Col>
                    </Row>
                    <Row style={{padding: 10}}>
                        <Col span={12}>
                            <table style={{width: '100%'}}>
                                <thead style={{borderBottom: '1px solid #000'}}>
                                <tr>
                                    <td><b>Volume(L) <br/> 容量(公升)</b></td>
                                    <td><b>Unit Price<br/> 單價</b></td>
                                    <td><b>Quantity<br/> 數量</b></td>
                                    <td><b>Price<br/> 金額</b></td>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    currentMonthOrders.map((v, i) => {
                                        return <tr key={i}>
                                            <td>{v.name}</td>
                                            <td>{v.unitPrice}</td>
                                            <td>{v.quantity}</td>
                                            <td>{(v.subTotalAmount).toFixed(2)}</td>
                                        </tr>
                                    })
                                }
                                </tbody>
                            </table>
                            <div><b>Total Amount 總金額: ${totalAmount}</b></div>
                        </Col>
                        <Col span={12}>
                            <ReactEcharts
                                option={this.chartOptions(currentMonthOrders)}
                                notMerge={true}
                                lazyUpdate={true}
                                //theme={"theme_name"}
                                //onChartReady={this.onChartReadyCallback}
                                //onEvents={EventsDict}
                                opts={{renderer: 'svg'}}
                            />
                        </Col>
                    </Row>
                    <Row style={{padding: 10}}>
                        <Col span={24}>
                            <table style={{width: '100%'}}>
                                <thead style={{borderBottom: '1px solid #000'}}>
                                <tr>
                                    <td><b>Type of Recyclables <br/> 回收物種類</b></td>
                                    <td><b>Weight of Recyclables Collected <br/>In This Month
                                        (kg)<br/> 本用收集的回收物重量(公斤)</b></td>
                                    <td><b>Weight of Recyclables Collected <br/>In Last Month
                                        (kg)<br/> 上月收集的回收物重量(公斤)</b></td>
                                    <td><b>Percentage Change<br/> 變動幅度</b></td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>Waste Paper 廢紙</td>
                                    <td>{currentMonthWasteUsage ? currentMonthWasteUsage.paper.toFixed(2) : '-'}</td>
                                    <td>{lastMonthWasteUsage ? lastMonthWasteUsage.paper.toFixed(2) : '-'}</td>
                                    <td>{currentMonthWasteUsage && lastMonthWasteUsage ? ((((currentMonthWasteUsage.paper - lastMonthWasteUsage.paper) / lastMonthWasteUsage.paper)) * 100).toFixed(2) + '%' : '-'}</td>
                                </tr>
                                <tr>
                                    <td>Waste Metals 廢金屬</td>
                                    <td>{currentMonthWasteUsage ? currentMonthWasteUsage.metal.toFixed(2) : '-'}</td>
                                    <td>{lastMonthWasteUsage ? lastMonthWasteUsage.metal.toFixed(2) : '-'}</td>
                                    <td>{currentMonthWasteUsage && lastMonthWasteUsage ? ((((currentMonthWasteUsage.metal - lastMonthWasteUsage.metal) / lastMonthWasteUsage.metal)) * 100).toFixed(2) + '%' : '-'}</td>
                                </tr>
                                <tr>
                                    <td>Waste Plastic 廢塑膠</td>
                                    <td>{currentMonthWasteUsage ? currentMonthWasteUsage.plastic.toFixed(2) : '-'}</td>
                                    <td>{lastMonthWasteUsage ? lastMonthWasteUsage.plastic.toFixed(2) : '-'}</td>
                                    <td>{currentMonthWasteUsage && lastMonthWasteUsage ? ((((currentMonthWasteUsage.plastic - lastMonthWasteUsage.plastic) / lastMonthWasteUsage.plastic)) * 100).toFixed(2) + '%' : '-'}</td>
                                </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <Row style={{padding: 10}}>
                        <Col span={24}>
                            <ReactEcharts
                                option={this.historyChartOptions(bagUsages, selectedMonthKey)}
                                notMerge={true}
                                lazyUpdate={true}
                                //theme={"theme_name"}
                                //onChartReady={this.onChartReadyCallback}
                                //onEvents={EventsDict}
                                opts={{renderer: 'svg'}}
                            />
                        </Col>
                    </Row>
                </div> : 'Please select the valid date.'}

        </div>
    }
}

export default OrgManagementStatement;
