/**
 * Big Waster API
 * This is a Big Waster API.
 *
 * OpenAPI spec version: 1.0
 * Contact: terry.wong@futureimpactlab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import RefundedbagCreateRecordParam from '../model/RefundedbagCreateRecordParam';
import RefundedbagOutputCmsRecord from '../model/RefundedbagOutputCmsRecord';
import RefundedbagOutputCmsRecords from '../model/RefundedbagOutputCmsRecords';
import RefundedbagUpdateRecordParam from '../model/RefundedbagUpdateRecordParam';
import ScheduleCreateScheduleParam from '../model/ScheduleCreateScheduleParam';
import ScheduleOutputCmsSchedule from '../model/ScheduleOutputCmsSchedule';
import ScheduleOutputCmsSchedules from '../model/ScheduleOutputCmsSchedules';
import ScheduleUpdateScheduleParam from '../model/ScheduleUpdateScheduleParam';
import UtilHttpErrorBody from '../model/UtilHttpErrorBody';
import UtilHttpOkBody from '../model/UtilHttpOkBody';
import UtilHttpRowAffBody from '../model/UtilHttpRowAffBody';
import WasterecordCreateRecordParam from '../model/WasterecordCreateRecordParam';
import WasterecordOutputCmsRecord from '../model/WasterecordOutputCmsRecord';
import WasterecordOutputCmsRecords from '../model/WasterecordOutputCmsRecords';
import WasterecordUpdateRecordParam from '../model/WasterecordUpdateRecordParam';

/**
* ManagementHelper service.
* @module api/ManagementHelperApi
* @version 1.0
*/
export default class ManagementHelperApi {

    /**
    * Constructs a new ManagementHelperApi. 
    * @alias module:api/ManagementHelperApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Create helper&#39;s record
     * @param {module:model/RefundedbagCreateRecordParam} refundedbagCreateRecordParam Create record parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UtilHttpOkBody} and HTTP response
     */
    managementHelperCreateRefundedBagWithHttpInfo(refundedbagCreateRecordParam) {
      let postBody = refundedbagCreateRecordParam;

      // verify the required parameter 'refundedbagCreateRecordParam' is set
      if (refundedbagCreateRecordParam === undefined || refundedbagCreateRecordParam === null) {
        throw new Error("Missing the required parameter 'refundedbagCreateRecordParam' when calling managementHelperCreateRefundedBag");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = UtilHttpOkBody;

      return this.apiClient.callApi(
        '/management/helper/refunded-bag', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create helper&#39;s record
     * @param {module:model/RefundedbagCreateRecordParam} refundedbagCreateRecordParam Create record parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UtilHttpOkBody}
     */
    managementHelperCreateRefundedBag(refundedbagCreateRecordParam) {
      return this.managementHelperCreateRefundedBagWithHttpInfo(refundedbagCreateRecordParam)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create schedule
     * @param {module:model/ScheduleCreateScheduleParam} scheduleCreateScheduleParam Create schedule parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UtilHttpOkBody} and HTTP response
     */
    managementHelperCreateScheduleWithHttpInfo(scheduleCreateScheduleParam) {
      let postBody = scheduleCreateScheduleParam;

      // verify the required parameter 'scheduleCreateScheduleParam' is set
      if (scheduleCreateScheduleParam === undefined || scheduleCreateScheduleParam === null) {
        throw new Error("Missing the required parameter 'scheduleCreateScheduleParam' when calling managementHelperCreateSchedule");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = UtilHttpOkBody;

      return this.apiClient.callApi(
        '/management/helper/schedule', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create schedule
     * @param {module:model/ScheduleCreateScheduleParam} scheduleCreateScheduleParam Create schedule parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UtilHttpOkBody}
     */
    managementHelperCreateSchedule(scheduleCreateScheduleParam) {
      return this.managementHelperCreateScheduleWithHttpInfo(scheduleCreateScheduleParam)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create helper&#39;s record
     * @param {module:model/WasterecordCreateRecordParam} wasterecordCreateRecordParam Create record parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UtilHttpOkBody} and HTTP response
     */
    managementHelperCreateWasteRecordWithHttpInfo(wasterecordCreateRecordParam) {
      let postBody = wasterecordCreateRecordParam;

      // verify the required parameter 'wasterecordCreateRecordParam' is set
      if (wasterecordCreateRecordParam === undefined || wasterecordCreateRecordParam === null) {
        throw new Error("Missing the required parameter 'wasterecordCreateRecordParam' when calling managementHelperCreateWasteRecord");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = UtilHttpOkBody;

      return this.apiClient.callApi(
        '/management/helper/waste-record', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create helper&#39;s record
     * @param {module:model/WasterecordCreateRecordParam} wasterecordCreateRecordParam Create record parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UtilHttpOkBody}
     */
    managementHelperCreateWasteRecord(wasterecordCreateRecordParam) {
      return this.managementHelperCreateWasteRecordWithHttpInfo(wasterecordCreateRecordParam)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete helper&#39;s record
     * @param {Number} recordId record id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UtilHttpRowAffBody} and HTTP response
     */
    managementHelperDeleteRefundedBagWithHttpInfo(recordId) {
      let postBody = null;

      // verify the required parameter 'recordId' is set
      if (recordId === undefined || recordId === null) {
        throw new Error("Missing the required parameter 'recordId' when calling managementHelperDeleteRefundedBag");
      }


      let pathParams = {
        'recordId': recordId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = UtilHttpRowAffBody;

      return this.apiClient.callApi(
        '/management/helper/refunded-bag/{recordId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete helper&#39;s record
     * @param {Number} recordId record id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UtilHttpRowAffBody}
     */
    managementHelperDeleteRefundedBag(recordId) {
      return this.managementHelperDeleteRefundedBagWithHttpInfo(recordId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete schedule
     * @param {Number} scheduleId schedule id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UtilHttpRowAffBody} and HTTP response
     */
    managementHelperDeleteScheduleWithHttpInfo(scheduleId) {
      let postBody = null;

      // verify the required parameter 'scheduleId' is set
      if (scheduleId === undefined || scheduleId === null) {
        throw new Error("Missing the required parameter 'scheduleId' when calling managementHelperDeleteSchedule");
      }


      let pathParams = {
        'scheduleId': scheduleId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = UtilHttpRowAffBody;

      return this.apiClient.callApi(
        '/management/helper/schedule/{scheduleId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete schedule
     * @param {Number} scheduleId schedule id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UtilHttpRowAffBody}
     */
    managementHelperDeleteSchedule(scheduleId) {
      return this.managementHelperDeleteScheduleWithHttpInfo(scheduleId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete helper&#39;s record
     * @param {Number} recordId record id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UtilHttpRowAffBody} and HTTP response
     */
    managementHelperDeleteWasteRecordWithHttpInfo(recordId) {
      let postBody = null;

      // verify the required parameter 'recordId' is set
      if (recordId === undefined || recordId === null) {
        throw new Error("Missing the required parameter 'recordId' when calling managementHelperDeleteWasteRecord");
      }


      let pathParams = {
        'recordId': recordId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = UtilHttpRowAffBody;

      return this.apiClient.callApi(
        '/management/helper/waste-record/{recordId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete helper&#39;s record
     * @param {Number} recordId record id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UtilHttpRowAffBody}
     */
    managementHelperDeleteWasteRecord(recordId) {
      return this.managementHelperDeleteWasteRecordWithHttpInfo(recordId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get specific hepler&#39;s record
     * @param {Number} recordId record id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/RefundedbagOutputCmsRecord} and HTTP response
     */
    managementHelperRefundedBagWithHttpInfo(recordId) {
      let postBody = null;

      // verify the required parameter 'recordId' is set
      if (recordId === undefined || recordId === null) {
        throw new Error("Missing the required parameter 'recordId' when calling managementHelperRefundedBag");
      }


      let pathParams = {
        'recordId': recordId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = RefundedbagOutputCmsRecord;

      return this.apiClient.callApi(
        '/management/helper/refunded-bag/{recordId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get specific hepler&#39;s record
     * @param {Number} recordId record id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/RefundedbagOutputCmsRecord}
     */
    managementHelperRefundedBag(recordId) {
      return this.managementHelperRefundedBagWithHttpInfo(recordId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get all helper&#39;s records
     * @param {Object} opts Optional parameters
     * @param {Number} opts.orgId org id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/RefundedbagOutputCmsRecords} and HTTP response
     */
    managementHelperRefundedBagsWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
        'orgId': opts['orgId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = RefundedbagOutputCmsRecords;

      return this.apiClient.callApi(
        '/management/helper/refunded-bag', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get all helper&#39;s records
     * @param {Object} opts Optional parameters
     * @param {Number} opts.orgId org id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/RefundedbagOutputCmsRecords}
     */
    managementHelperRefundedBags(opts) {
      return this.managementHelperRefundedBagsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get specific schedule
     * @param {Number} scheduleId schedule id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ScheduleOutputCmsSchedule} and HTTP response
     */
    managementHelperScheduleWithHttpInfo(scheduleId) {
      let postBody = null;

      // verify the required parameter 'scheduleId' is set
      if (scheduleId === undefined || scheduleId === null) {
        throw new Error("Missing the required parameter 'scheduleId' when calling managementHelperSchedule");
      }


      let pathParams = {
        'scheduleId': scheduleId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ScheduleOutputCmsSchedule;

      return this.apiClient.callApi(
        '/management/helper/schedule/{scheduleId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get specific schedule
     * @param {Number} scheduleId schedule id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ScheduleOutputCmsSchedule}
     */
    managementHelperSchedule(scheduleId) {
      return this.managementHelperScheduleWithHttpInfo(scheduleId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get all schedule
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ScheduleOutputCmsSchedules} and HTTP response
     */
    managementHelperSchedulesWithHttpInfo() {
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ScheduleOutputCmsSchedules;

      return this.apiClient.callApi(
        '/management/helper/schedule', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get all schedule
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ScheduleOutputCmsSchedules}
     */
    managementHelperSchedules() {
      return this.managementHelperSchedulesWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update helper&#39;s record
     * @param {Number} recordId record id
     * @param {module:model/RefundedbagUpdateRecordParam} refundedbagUpdateRecordParam Update helper record parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UtilHttpRowAffBody} and HTTP response
     */
    managementHelperUpdateRefundedBagWithHttpInfo(recordId, refundedbagUpdateRecordParam) {
      let postBody = refundedbagUpdateRecordParam;

      // verify the required parameter 'recordId' is set
      if (recordId === undefined || recordId === null) {
        throw new Error("Missing the required parameter 'recordId' when calling managementHelperUpdateRefundedBag");
      }

      // verify the required parameter 'refundedbagUpdateRecordParam' is set
      if (refundedbagUpdateRecordParam === undefined || refundedbagUpdateRecordParam === null) {
        throw new Error("Missing the required parameter 'refundedbagUpdateRecordParam' when calling managementHelperUpdateRefundedBag");
      }


      let pathParams = {
        'recordId': recordId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = UtilHttpRowAffBody;

      return this.apiClient.callApi(
        '/management/helper/refunded-bag/{recordId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update helper&#39;s record
     * @param {Number} recordId record id
     * @param {module:model/RefundedbagUpdateRecordParam} refundedbagUpdateRecordParam Update helper record parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UtilHttpRowAffBody}
     */
    managementHelperUpdateRefundedBag(recordId, refundedbagUpdateRecordParam) {
      return this.managementHelperUpdateRefundedBagWithHttpInfo(recordId, refundedbagUpdateRecordParam)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update schedule
     * @param {Number} scheduleId schedule id
     * @param {module:model/ScheduleUpdateScheduleParam} scheduleUpdateScheduleParam Update schedule parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UtilHttpRowAffBody} and HTTP response
     */
    managementHelperUpdateScheduleWithHttpInfo(scheduleId, scheduleUpdateScheduleParam) {
      let postBody = scheduleUpdateScheduleParam;

      // verify the required parameter 'scheduleId' is set
      if (scheduleId === undefined || scheduleId === null) {
        throw new Error("Missing the required parameter 'scheduleId' when calling managementHelperUpdateSchedule");
      }

      // verify the required parameter 'scheduleUpdateScheduleParam' is set
      if (scheduleUpdateScheduleParam === undefined || scheduleUpdateScheduleParam === null) {
        throw new Error("Missing the required parameter 'scheduleUpdateScheduleParam' when calling managementHelperUpdateSchedule");
      }


      let pathParams = {
        'scheduleId': scheduleId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = UtilHttpRowAffBody;

      return this.apiClient.callApi(
        '/management/helper/schedule/{scheduleId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update schedule
     * @param {Number} scheduleId schedule id
     * @param {module:model/ScheduleUpdateScheduleParam} scheduleUpdateScheduleParam Update schedule parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UtilHttpRowAffBody}
     */
    managementHelperUpdateSchedule(scheduleId, scheduleUpdateScheduleParam) {
      return this.managementHelperUpdateScheduleWithHttpInfo(scheduleId, scheduleUpdateScheduleParam)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update helper&#39;s record
     * @param {Number} recordId record id
     * @param {module:model/WasterecordUpdateRecordParam} wasterecordUpdateRecordParam Update helper record parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UtilHttpRowAffBody} and HTTP response
     */
    managementHelperUpdateWasteRecordWithHttpInfo(recordId, wasterecordUpdateRecordParam) {
      let postBody = wasterecordUpdateRecordParam;

      // verify the required parameter 'recordId' is set
      if (recordId === undefined || recordId === null) {
        throw new Error("Missing the required parameter 'recordId' when calling managementHelperUpdateWasteRecord");
      }

      // verify the required parameter 'wasterecordUpdateRecordParam' is set
      if (wasterecordUpdateRecordParam === undefined || wasterecordUpdateRecordParam === null) {
        throw new Error("Missing the required parameter 'wasterecordUpdateRecordParam' when calling managementHelperUpdateWasteRecord");
      }


      let pathParams = {
        'recordId': recordId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = UtilHttpRowAffBody;

      return this.apiClient.callApi(
        '/management/helper/waste-record/{recordId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update helper&#39;s record
     * @param {Number} recordId record id
     * @param {module:model/WasterecordUpdateRecordParam} wasterecordUpdateRecordParam Update helper record parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UtilHttpRowAffBody}
     */
    managementHelperUpdateWasteRecord(recordId, wasterecordUpdateRecordParam) {
      return this.managementHelperUpdateWasteRecordWithHttpInfo(recordId, wasterecordUpdateRecordParam)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get specific hepler&#39;s record
     * @param {Number} recordId record id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WasterecordOutputCmsRecord} and HTTP response
     */
    managementHelperWasteRecordWithHttpInfo(recordId) {
      let postBody = null;

      // verify the required parameter 'recordId' is set
      if (recordId === undefined || recordId === null) {
        throw new Error("Missing the required parameter 'recordId' when calling managementHelperWasteRecord");
      }


      let pathParams = {
        'recordId': recordId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = WasterecordOutputCmsRecord;

      return this.apiClient.callApi(
        '/management/helper/waste-record/{recordId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get specific hepler&#39;s record
     * @param {Number} recordId record id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WasterecordOutputCmsRecord}
     */
    managementHelperWasteRecord(recordId) {
      return this.managementHelperWasteRecordWithHttpInfo(recordId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get all helper&#39;s records
     * @param {Object} opts Optional parameters
     * @param {Number} opts.orgId org id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WasterecordOutputCmsRecords} and HTTP response
     */
    managementHelperWasteRecordsWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
        'orgId': opts['orgId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = WasterecordOutputCmsRecords;

      return this.apiClient.callApi(
        '/management/helper/waste-record', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get all helper&#39;s records
     * @param {Object} opts Optional parameters
     * @param {Number} opts.orgId org id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WasterecordOutputCmsRecords}
     */
    managementHelperWasteRecords(opts) {
      return this.managementHelperWasteRecordsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
