// export const RETRIEVE_ORG = 'RETRIEVE_ORG';
// export const RETRIEVE_USER = 'RETRIEVE_USER';
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import orgInfo from './org-info'
import user from './user'
import login from './login'
import init from './init'
import orgPageIsLoading from './org-page-is-loading'

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    orgInfo, orgPageIsLoading, user, login, init
});

export default createRootReducer;