import React, {Component} from 'react'
import DataTable, {filterType} from "~/src/components/DataTable";
import {Button, Menu, Icon, Drawer, Form, Input, DatePicker, Select, notification} from 'antd'
import _ from 'lodash'

import ManagementUserApi from "~/src/api-client/api/ManagementUserApi"

import {FormType} from "~/src/utils/form"
import {OperationColumn} from '~/src/utils/data-table'

import ManagementHelperApi from "~/src/api-client/api/ManagementHelperApi"
import moment from 'moment'
import csv from "~/src/csv"
import CUForm from "./Form";
import {ManagementOrgBagUsageApi} from "~/src/api-client";
import ShopApi from "~/src/api-client/api/ManagementShopApi";
import ManagementOrgBagOrderApi from "~/src/api-client/api/ManagementOrgBagOrderApi";

class ManagementOrgAdminUser extends Component {
    state = {
        filteredValue: {},
        result: {users: []},
        isOpenCreateDrawer: false,
        isOpenUpdateDrawer: false,
        modifyFormData: {}
    };

    static UserList(orgId) {
        return new ManagementUserApi().managementUsers({org: orgId}).then(({users: allUsers}) => {
            let users = _(allUsers).filter({role: 'schoolAdmin'}).value();
            return users;
        })
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.refreshData();
    }

    handleOperationBarOnClick = ({item, key}) => {
        if (key == FormType.create) {
            this.setState({isOpenCreateDrawer: true})
        }
    };

    handleRecordModifyOnClick = (record) => {
        this.setState({isOpenUpdateDrawer: true, modifyFormData: record})
    };

    handleCUFormOnClose = () => {
        this.setState({isOpenCreateDrawer: false, isOpenUpdateDrawer: false})
    };

    handleDataChange = () => {
        this.refreshData();
        this.setState({isOpenCreateDrawer: false, isOpenUpdateDrawer: false})
    };

    refreshData = () => {
        this.props.setOrgPageIsLoading(true);
        const orgId = this.props.match.params.orgId;
        this.constructor.UserList(orgId).then((users) => {
            let newResult = {...this.state.result};
            newResult.users = users;
            this.setState({result: newResult});
        }).catch((err) => {
            notification.warning({message: `Cannot refresh data`, description: `${err}`, duration: 0});
        }).finally(()=>{
            this.props.setOrgPageIsLoading(false);
        })
    };

    handleDeleteRecord = (record) => {
        this.props.setOrgPageIsLoading(true);
        return new ManagementUserApi().managementDeleteUser(record.id).then(() => {
            if (record.haveShopper) {
                return new ManagementUserApi().managementDeleteUser(record.shopperId)
            }
            return null
        }).then(() => {
            notification.success({message: `${record.name} was successfully deleted`})
        }).catch((err) => {
            notification.warning({message: `Cannot delete ${record.name}`, description: `${err}`, duration: 0});
        }).finally(() => {
            this.props.setOrgPageIsLoading(false);
            this.refreshData();
        })
    };


    render() {
        const {modifyFormData, isOpenCreateDrawer, isOpenUpdateDrawer, result, filteredValue} = this.state;
        const {users: data} = result;
        const {orgInfo} = this.props;

        let columns = [
            // {
            //     title: 'Username',
            //     dataIndex: 'username',
            //     sorter: (a, b) => a.abbv.localeCompare(b.abbv),
            // },
            {
                title: 'Username',
                dataIndex: 'username',
                sorter: (a, b) => a.name.localeCompare(b.name),
            }, {
                title: 'Created at',
                dataIndex: 'createdAt',
                render: text => new Date(text).toLocaleString(),
                sorter: (a, b) => a.createdAt - b.createdAt,
            }, OperationColumn(null, this.handleRecordModifyOnClick, this.handleDeleteRecord)
        ];

        return <div>
            <DataTable operationBarComponent={<OperationBarComponent onClick={this.handleOperationBarOnClick}/>}
                       columns={columns}
                       data={data}
                       pageSize={50}
                       hideFilter={true}
                       rowKey="id"
            />

            {/* Create Form */}
            {isOpenCreateDrawer &&
            <CUForm
                title={'Create a new user'}
                isOpen={isOpenCreateDrawer}
                formType={FormType.create}
                org={orgInfo}
                onClose={this.handleCUFormOnClose}
                onSuccess={this.handleDataChange}
            />}

            {/* Update Form */}
            {isOpenUpdateDrawer &&
            <CUForm
                title={'Modify a user'}
                data={modifyFormData}
                org={orgInfo}
                isOpen={isOpenUpdateDrawer}
                formType={FormType.modify}
                onClose={this.handleCUFormOnClose}
                onSuccess={this.handleDataChange}
            />}

        </div>
    }
}

const OperationBarComponent = (props) => {
    return <Menu
        mode="horizontal"
        selectable={false}
        onClick={props.onClick}
    >
        <Menu.Item key={FormType.create}>
            <Icon type="plus"/>Create
        </Menu.Item>

    </Menu>
};

export default ManagementOrgAdminUser;
