export const actionType = {
    RESET: 'RESET',
    INIT: 'INIT',
    INIT_FINISH: 'INIT_FINISH',
    LOGIN: 'LOGIN',
    LOGIN_ERROR: 'LOGIN_ERROR',
    LOGOUT: 'LOGOUT',
    CHANGE_LOGIN_PROCESSING_STATE: 'CHANGE_LOGIN_PROCESSING_STATE',

    RESET_CURRENT_ORG: 'RESET_CURRENT_ORG',
    FETCH_CURRENT_ORG: 'FETCH_CURRENT_ORG',
    FETCH_CURRENT_ORG_SUCCESS: 'FETCH_CURRENT_ORG_SUCCESS',
    FETCH_CURRENT_ORG_ERROR: 'FETCH_CURRENT_ORG_ERROR',

    FETCH_LOGIN_USER: 'FETCH_LOGIN_USER',
    FETCH_LOGIN_USER_SUCCESS: 'FETCH_LOGIN_USER_SUCCESS',
    FETCH_LOGIN_USER_ERROR: 'FETCH_LOGIN_USER_ERROR',

    SAVE_USER_TOKEN: 'SAVE_USER_TOKEN',

    SET_ORG_PAGE_IS_LOADING: 'SET_ORG_PAGE_IS_LOADING',
    RESET_ORG_PAGE_IS_LOADING: 'RESET_ORG_PAGE_IS_LOADING',

};

export function logout() {
    return { type: actionType.LOGOUT}
}

export function login (username, password) {
    return {type: actionType.LOGIN, username: username, password: password}
}

export function fetchCurrentOrg (orgId) {
    return {type: actionType.FETCH_CURRENT_ORG, orgId: orgId}
}

export function setOrgPageIsLoading(isLoading) {
    return {type: actionType.SET_ORG_PAGE_IS_LOADING, isLoading: isLoading}
}

export function resetOrgPageIsLoading(isLoading) {
    return {type: actionType.RESET_ORG_PAGE_IS_LOADING, isLoading: isLoading}
}
