import React from 'react'
import Panel from "~/src/layout/Panel"
import { renderRoutes } from "react-router-config";

// Management Route
import ManagementOrg from "~/src/page/ManagementOrg"
import ManagementOrgList from "~/src/page/ManagementOrgList"
import ManagementOrgUser from "~/src/page/ManagementOrgUser"
import ManagementOrgShopItem from "~/src/page/ManagementOrgShopItem"
import ManagementOrgShopOrder from "~/src/page/ManagementOrgShopOrder"
import ManagementOrgStatement from "~/src/page/ManagementOrgStatement"
import ManagementOrgUsageBag from "~/src/page/ManagementOrgUsageBag"
import ManagementOrgUsageWaste from "~/src/page/ManagementOrgUsageWaste"
import ManagementOrgRefundedBag from "~/src/page/ManagementOrgRefundedBag"
import ManagementOrgCompetition from "~/src/page/ManagementOrgCompetition"

import NotFoundRedirect from "~/src/components/NotFoundRedirect"
import ManagementOrgOrderBag from "~/src/page/ManagementOrgOrderBag";
import ManagementOrgAdminUser from "~/src/page/ManagementOrgAdminUser";
const routes = [
    {
        path: "/management/org",
        component: ManagementOrgList,
        exact: true,
        strict: true,
    },
    {
        path: "/management/org/:orgId",
        component: ManagementOrg,
        strict: true,
        routes: [
            {
                path: "/management/org/:orgId/user",
                component: ManagementOrgUser,
                exact: true,
                strict: true,
            },
            {
                path: "/management/org/:orgId/competition",
                component: ManagementOrgCompetition,
                exact: true,
                strict: true,
            },
            {
                path: "/management/org/:orgId/shop/item",
                component: ManagementOrgShopItem,
                exact: true,
                strict: true,
            },
            {
                path: "/management/org/:orgId/shop/order",
                component: ManagementOrgShopOrder,
                exact: true,
                strict: true,
            },
            {
                path: "/management/org/:orgId/refunded-bag",
                component: ManagementOrgRefundedBag,
                exact: true,
                strict: true,
            },
            {
                path: "/management/org/:orgId/usage/waste",
                component: ManagementOrgUsageWaste,
                exact: true,
                strict: true,
            },
            {
                path: "/management/org/:orgId/usage/bag",
                component: ManagementOrgUsageBag,
                exact: true,
                strict: true,
            },
            {
                path: "/management/org/:orgId/statement",
                component: ManagementOrgStatement,
                exact: true,
                strict: true,
            },
            {
                path: "/management/org/:orgId/helper-schedule",
                component: ManagementOrgUser,
                exact: true,
                strict: true,
            },
            {
                path: "/management/org/:orgId/bag-order",
                component: ManagementOrgOrderBag,
                exact: true,
                strict: true,
            },
            {
                path: "/management/org/:orgId/admin-user",
                component: ManagementOrgAdminUser,
                exact: true,
                strict: true,
            },
            {
                component: NotFoundRedirect,
            },
        ],

    },
    {
        component: NotFoundRedirect,
    },
];

export default () => {
    return <Panel>
        {renderRoutes(routes)}
    </Panel>
}