import {actionType} from "~/src/redux/action";

export default function (state = false, {type, isFinish, error}) {
    switch (type) {
        case actionType.INIT_FINISH:
            return isFinish;
        default:
            return state
    }
}
