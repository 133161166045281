import React, {Component} from 'react'
import DataTable, {filterType} from "~/src/components/DataTable";
import {Button, Menu, Icon, Drawer, Form, Select, notification, Input} from 'antd'
import ManagementUserApi from "~/src/api-client/api/ManagementUserApi"
import {FormType} from "~/src/utils/form"
import {OperationColumn} from '~/src/utils/data-table'
import ShopApi from "~/src/api-client/api/ManagementShopApi";
import CUForm from './Form'

class OrgManagementShopItem extends Component {
    state = {
        filteredValue: {},
        enabledItems: [],
        isOpenCreateDrawer: false,
        isOpenUpdateDrawer: false,
        modifyFormData: {},
        itemList: []
    };

    static enabledItems(orgId) {
        return new ShopApi().managementShopItems().then(({items}) => {
            let il = items.filter((v)=>{
                console.log(v)
                return v.availableOrgIds != null && v.availableOrgIds.indexOf(parseInt(orgId)) >= 0
            });
            return il;
        })
    }

    static itemList() {
        return new ShopApi().managementShopItems().then(({items}) => {
            return items;
        })
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.constructor.itemList().then((itemList)=>{
            this.setState({itemList: itemList});
        })
        this.refreshData()
    }

    handleOperationBarOnClick = ({item, key}) => {
        if (key == FormType.create) {
            this.setState({isOpenCreateDrawer: true})
        }
    };

    handleRecordModifyOnClick = (record) => {
        this.setState({isOpenUpdateDrawer: true, modifyFormData: record})
    };

    handleCUFormOnClose = () => {
        this.setState({isOpenCreateDrawer: false, isOpenUpdateDrawer: false})
    };

    handleDataChange = () => {
        this.refreshData();
        this.setState({isOpenCreateDrawer: false, isOpenUpdateDrawer: false})
    };

    refreshData = () => {
        this.props.setOrgPageIsLoading(true);
        this.constructor.enabledItems(this.props.match.params.orgId).then((enabledItems) => {
            this.setState({enabledItems: enabledItems});
        }).catch((err) => {
            notification.warning({message: `Cannot refresh data`, description: `${err}`, duration: 0});
        }).finally(()=>{
            this.props.setOrgPageIsLoading(false);
        })
    };

    handleDeleteRecord = (record) => {
        this.props.setOrgPageIsLoading(true);
        record.availableOrgIds.splice(record.availableOrgIds.indexOf(this.props.orgInfo.id), 1);
        return new ShopApi().managementShopUpdateItem(record.id, record).then(() => {
            this.refreshData();
        }).then(() => {
            notification.success({message: `${record.name} was successfully deleted`})
        }).catch((err) => {
            notification.warning({message: `Cannot delete ${record.name}`, description: `${err}`, duration: 0});
        }).finally(() => {
            this.props.setOrgPageIsLoading(false);
            this.refreshData();
        })
    };

    render() {
        const {modifyFormData, isOpenCreateDrawer, isOpenUpdateDrawer, itemList, enabledItems} = this.state;
        const {orgInfo} = this.props;
        let columns = [
            // {
            //     title: 'Username',
            //     dataIndex: 'username',
            //     sorter: (a, b) => a.abbv.localeCompare(b.abbv),
            // },
            {
                title: 'Name',
                dataIndex: 'name',
                sorter: (a, b) => a.name.localeCompare(b.name),
            }, OperationColumn(null, null, this.handleDeleteRecord)
        ];

        return <div>
            <DataTable operationBarComponent={<OperationBarComponent onClick={this.handleOperationBarOnClick}/>}
                       columns={columns}
                       data={enabledItems}
                       pageSize={50}
                       hideFilter={true}
                       rowKey="id"
            />

            {/* Create Form */}
            {isOpenCreateDrawer &&
            <CUForm
                title={'Create a new item'}
                isOpen={isOpenCreateDrawer}
                formType={FormType.create}
                org={orgInfo}
                itemList={itemList.filter((v)=>{
                    return !enabledItems.find((x)=> x.name == v.name)
                })}
                onClose={this.handleCUFormOnClose}
                onSuccess={this.handleDataChange}
            />}
        </div>
    }
}

const OperationBarComponent = (props) => {
    return <Menu
        mode="horizontal"
        selectable={false}
        onClick={props.onClick}
    >
        <Menu.Item key={FormType.create}>
            <Icon type="plus"/>Create
        </Menu.Item>
    </Menu>
};

export default OrgManagementShopItem;
