/**
 * Big Waster API
 * This is a Big Waster API.
 *
 * OpenAPI spec version: 1.0
 * Contact: terry.wong@futureimpactlab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import BagorderCreateBagOrderParam from '../model/BagorderCreateBagOrderParam';
import BagorderOutput from '../model/BagorderOutput';
import BagorderUpdateBagOrderParam from '../model/BagorderUpdateBagOrderParam';
import UtilHttpErrorBody from '../model/UtilHttpErrorBody';

/**
* ManagementOrgBagOrder service.
* @module api/ManagementOrgBagOrderApi
* @version 1.0
*/
export default class ManagementOrgBagOrderApi {

    /**
    * Constructs a new ManagementOrgBagOrderApi. 
    * @alias module:api/ManagementOrgBagOrderApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Create bag usages
     * @param {Number} orgId org id
     * @param {module:model/BagorderCreateBagOrderParam} bagorderCreateBagOrderParam Create bag Usage parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    managementOrgBagOrderCreateWithHttpInfo(orgId, bagorderCreateBagOrderParam) {
      let postBody = bagorderCreateBagOrderParam;

      // verify the required parameter 'orgId' is set
      if (orgId === undefined || orgId === null) {
        throw new Error("Missing the required parameter 'orgId' when calling managementOrgBagOrderCreate");
      }

      // verify the required parameter 'bagorderCreateBagOrderParam' is set
      if (bagorderCreateBagOrderParam === undefined || bagorderCreateBagOrderParam === null) {
        throw new Error("Missing the required parameter 'bagorderCreateBagOrderParam' when calling managementOrgBagOrderCreate");
      }


      let pathParams = {
        'orgId': orgId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;

      return this.apiClient.callApi(
        '/management/org/{orgId}/bag-order', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create bag usages
     * @param {Number} orgId org id
     * @param {module:model/BagorderCreateBagOrderParam} bagorderCreateBagOrderParam Create bag Usage parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    managementOrgBagOrderCreate(orgId, bagorderCreateBagOrderParam) {
      return this.managementOrgBagOrderCreateWithHttpInfo(orgId, bagorderCreateBagOrderParam)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete bag usages
     * @param {Number} orgId org id
     * @param {Number} bagOrderId bagOrderId
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    managementOrgBagOrderDeleteWithHttpInfo(orgId, bagOrderId) {
      let postBody = null;

      // verify the required parameter 'orgId' is set
      if (orgId === undefined || orgId === null) {
        throw new Error("Missing the required parameter 'orgId' when calling managementOrgBagOrderDelete");
      }

      // verify the required parameter 'bagOrderId' is set
      if (bagOrderId === undefined || bagOrderId === null) {
        throw new Error("Missing the required parameter 'bagOrderId' when calling managementOrgBagOrderDelete");
      }


      let pathParams = {
        'orgId': orgId,
        'bagOrderId': bagOrderId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = null;

      return this.apiClient.callApi(
        '/management/org/{orgId}/bag-order/{bagOrderId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete bag usages
     * @param {Number} orgId org id
     * @param {Number} bagOrderId bagOrderId
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    managementOrgBagOrderDelete(orgId, bagOrderId) {
      return this.managementOrgBagOrderDeleteWithHttpInfo(orgId, bagOrderId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update bag usages
     * @param {Number} orgId org id
     * @param {Number} bagOrderId bagOrderId
     * @param {module:model/BagorderUpdateBagOrderParam} bagorderUpdateBagOrderParam Update bag Usage parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    managementOrgBagOrderUpdateWithHttpInfo(orgId, bagOrderId, bagorderUpdateBagOrderParam) {
      let postBody = bagorderUpdateBagOrderParam;

      // verify the required parameter 'orgId' is set
      if (orgId === undefined || orgId === null) {
        throw new Error("Missing the required parameter 'orgId' when calling managementOrgBagOrderUpdate");
      }

      // verify the required parameter 'bagOrderId' is set
      if (bagOrderId === undefined || bagOrderId === null) {
        throw new Error("Missing the required parameter 'bagOrderId' when calling managementOrgBagOrderUpdate");
      }

      // verify the required parameter 'bagorderUpdateBagOrderParam' is set
      if (bagorderUpdateBagOrderParam === undefined || bagorderUpdateBagOrderParam === null) {
        throw new Error("Missing the required parameter 'bagorderUpdateBagOrderParam' when calling managementOrgBagOrderUpdate");
      }


      let pathParams = {
        'orgId': orgId,
        'bagOrderId': bagOrderId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;

      return this.apiClient.callApi(
        '/management/org/{orgId}/bag-order/{bagOrderId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update bag usages
     * @param {Number} orgId org id
     * @param {Number} bagOrderId bagOrderId
     * @param {module:model/BagorderUpdateBagOrderParam} bagorderUpdateBagOrderParam Update bag Usage parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    managementOrgBagOrderUpdate(orgId, bagOrderId, bagorderUpdateBagOrderParam) {
      return this.managementOrgBagOrderUpdateWithHttpInfo(orgId, bagOrderId, bagorderUpdateBagOrderParam)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get bag usages
     * @param {Number} orgId org id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BagorderOutput} and HTTP response
     */
    managementOrgBagOrdersWithHttpInfo(orgId) {
      let postBody = null;

      // verify the required parameter 'orgId' is set
      if (orgId === undefined || orgId === null) {
        throw new Error("Missing the required parameter 'orgId' when calling managementOrgBagOrders");
      }


      let pathParams = {
        'orgId': orgId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = BagorderOutput;

      return this.apiClient.callApi(
        '/management/org/{orgId}/bag-order', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get bag usages
     * @param {Number} orgId org id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BagorderOutput}
     */
    managementOrgBagOrders(orgId) {
      return this.managementOrgBagOrdersWithHttpInfo(orgId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
