import './HomeButton.scss';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Icon } from 'antd';
import { connect } from "react-redux";

class HomeButton extends Component {
    render() {
        const { history } = this.props;

        return (
            <div className="HomeButton" onClick={() => history.push('/management/org')}>
                <Icon type="home" />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user
});

export default withRouter(HomeButton);