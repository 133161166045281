
// Create and Update Form
import {Button, Checkbox, DatePicker, Drawer, Form, Input, notification, Select} from "antd";
import React, {Component} from "react";
import {FormType} from "~/src/utils/form";
import ShopApi from "~/src/api-client/api/ManagementShopApi";
import moment from "moment";
import {ManagementOrgBagUsageApi} from "~/src/api-client";
import ManagementOrgBagOrderApi from "~/src/api-client/api/ManagementOrgBagOrderApi";
import ManagementUserApi from "~/src/api-client/api/ManagementUserApi";

@Form.create()
class CUForm extends Component {
    static defaultProps = {
        formType: FormType.create,
        data: {

        }
    };

    state = {
        haveShopper: false,
    }

    create = (data) => {
        // Create User
        new ManagementUserApi().managementCreateUser({
            username: data.username,
            name: data.username,
            password: data.password,
            role: 'schoolAdmin',
            orgId: this.props.org.id,
            parentUserId: null,
        }).then(() => {
            if (this.props.onSuccess) {
                this.props.onSuccess()
            }
            notification.success({message: `${data.name} was successfully created`})
        }).catch((err) => {
            notification.warning({message: `Cannot create ${data.name}`, description: `${err}`, duration: 0});
        })
    };

    modify = (data) => {
        // Update User
        new ManagementUserApi().managementUpdateUser(this.props.data.id, {
            username: data.username,
            name: data.username,
            password: data.password,
            role: 'schoolAdmin',
            orgId: this.props.org.id,
            parentUserId: null,
        }).then(() => {
            if (this.props.onSuccess) {
                this.props.onSuccess()
            }
            notification.success({message: `${data.name} was successfully modified`})
        }).catch((err) => {
            notification.warning({
                message: `Cannot modify  ${this.props.data.name}`,
                description: `${err}`,
                duration: 0
            });
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (err) {
                return;
            }

            console.log('Received values of form: ', values);
            this[this.props.formType](values)
        });
    }

    componentDidMount() {
        // Class list
    }

    render() {
        // Order Date
        // Class
        // Item
        // Qty
        // Revised Qty
        // Status
        const {getFieldDecorator} = this.props.form;
        const {username} = this.props.data;
        const {orgUsers, itemList} = this.props;

        return <Drawer
            title={this.props.title}
            width={720}
            onClose={this.props.onClose}
            visible={this.props.isOpen}
        >
            <Form layout="vertical" onSubmit={this.handleSubmit}>
                {/* Class */}
                <Form.Item label="Username" hasFeedback colon={false}>
                    {getFieldDecorator('username', {rules: [{required: true}], initialValue: username})(
                        <Input type="text"/>
                    )}
                </Form.Item>
                <Form.Item label="Password" hasFeedback colon={false}>
                    {getFieldDecorator('password', {
                        rules: [{required: true}],
                    })(
                        <Input type="password"/>
                    )}
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">Submit</Button>
                </Form.Item>
            </Form>
        </Drawer>
    }
}

export default CUForm;