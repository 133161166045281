import { call, put, apply, takeEvery, takeLatest } from 'redux-saga/effects'
import {actionType} from "../action";
import jwtDecode from 'jwt-decode';
import AuthApi from '~/src/api-client/api/AuthApi'
import AuthLoginParam from '~/src/api-client/model/AuthLoginParam'
import ManagementUserApi from "~/src/api-client/api/ManagementUserApi";
import ApiClient from "~/src/api-client/ApiClient";
import ManagementOrgApi from "~/src/api-client/api/ManagementOrgApi";
import {push} from 'connected-react-router'
import {isEmpty} from 'lodash'

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* fetchCurrentOrg(action) {
    try {
        let orgApi = new ManagementOrgApi();
        let orgInfo = yield apply(orgApi, orgApi.managementOrg, [action.orgId]);
        yield put({type: actionType.FETCH_CURRENT_ORG_SUCCESS, org: orgInfo.org});
    } catch (e) {
        yield put({type: actionType.FETCH_CURRENT_ORG_ERROR, error: e.response ? e.response.body.error: e});
    }
}

function* fetchLoginUser(action) {
    try {
        let userApi = new ManagementUserApi();
        const token = userToken();
        var tokenInfo = jwtDecode(token);
        let userInfo = yield apply(userApi, userApi.managementUser, [tokenInfo.id]);
        if(action.isNewLogin) {
            if(userInfo.user.role == 'admin'){
                yield put(push({pathname: '/management/org'}))
            } else if(userInfo.user.role == 'helper'){
                yield put(push({pathname: '/management/org'}))
            } else if(userInfo.user.role == 'schoolAdmin' && !isEmpty(userInfo.user.org)){
                yield put(push({pathname: '/management/org/' + userInfo.user.org.id + '/bag-order'}))
            } else {
                yield put({type: actionType.LOGOUT});
            }
        }
        yield put({type: actionType.FETCH_LOGIN_USER_SUCCESS, user: userInfo.user});
        yield put({type: actionType.INIT_FINISH, isFinish: true});
    } catch (e) {
       yield put({type: actionType.FETCH_LOGIN_USER_ERROR, error: e.response ? e.response.body.error: e});
    }
}

function* login({username, password}){
    yield put({type: actionType.CHANGE_LOGIN_PROCESSING_STATE, isLoggingIn: true, error: null});
    try {
        let authApi = new AuthApi();
        let tokenInfo = yield apply(authApi, authApi.authLogin, [{authLoginParam: new AuthLoginParam(password, username)}]);

        saveUserToken(tokenInfo.token);
        setApiClientAuth(tokenInfo.token);
        // fetch login user
        yield put({type: actionType.FETCH_LOGIN_USER, isNewLogin: true});

    } catch (e) {
        yield put({type: actionType.LOGIN_ERROR, error: e.response ? e.response.body.Message: e});
    } finally {
        yield put({type: actionType.CHANGE_LOGIN_PROCESSING_STATE, isLoggingIn: false});
    }
}

function* logout(action){
    sessionStorage.removeItem('token');
    // yield put(push({pathname: '/'}));
    document.location.href="/";
}

function* init(action) {

    // fetch user
    const token = userToken();
    if(token){
        setApiClientAuth(token);
        yield put({type: actionType.FETCH_LOGIN_USER});
    }

}

function saveUserToken(token){
    sessionStorage.setItem('token', token);
}

function userToken(){
    return sessionStorage.getItem('token');
}

function setApiClientAuth(token){
    ApiClient.instance.authentications['ApiKeyAuth'].apiKey = "Bearer " + token;
}

/*
  Starts fetchUser on each dispatched `USER_FETCH_REQUESTED` action.
  Allows concurrent fetches of user.
*/
function* saga() {
    yield takeEvery(actionType.INIT, init);
    yield takeEvery(actionType.LOGIN, login);
    yield takeEvery(actionType.LOGOUT, logout);
    yield takeEvery(actionType.FETCH_LOGIN_USER, fetchLoginUser);
    yield takeEvery(actionType.SAVE_USER_TOKEN, saveUserToken);
    yield takeEvery(actionType.FETCH_CURRENT_ORG, fetchCurrentOrg);

}

export default saga;