import React, {Component} from 'react'
import DataTable, {filterType} from "~/src/components/DataTable";

import {Button, Menu, Icon, Drawer, Form, Input, DatePicker, Select, notification} from 'antd'
import _ from 'lodash'

import ManagementUserApi from "~/src/api-client/api/ManagementUserApi"
import ManagementOrgApi from "~/src/api-client/api/ManagementOrgApi"

import {FormType} from "~/src/utils/form"
import {OperationColumn} from '~/src/utils/data-table'

import ManagementHelperApi from "~/src/api-client/api/ManagementHelperApi";
import moment from 'moment'
import CUForm from "~/src/page/ManagementOrgRefundedBag/From";
import csv from "~/src/csv";

class OrgManagementRefundedBag extends Component {
    state = {
        filteredValue: {},
        efundedBags: [], orgUsers: [],
        isOpenCreateDrawer: false,
        isOpenUpdateDrawer: false,
        modifyFormData: {}
    };

    static OrgUsers(orgId) {
        return new ManagementUserApi().managementUsers({org: orgId}).then(({users: allUsers}) => {
            let users = _(allUsers).filter((v) => v.role == 'user' || v.role == 'org').value();
            return users;
        })
    }

    static RefundedBags(orgId) {
        return new ManagementHelperApi().managementHelperRefundedBags({orgId: orgId}).then(({records}) => {
            return records;
        })
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const orgId = this.props.orgInfo.id;
        this.props.setOrgPageIsLoading(true);
        this.constructor.OrgUsers(orgId).then((orgUsers)=>{
            this.setState({orgUsers: orgUsers});
        }).finally(()=>{
            this.props.setOrgPageIsLoading(false);
        });
        this.refreshData()
    }
    
    handleOperationBarOnClick = ({item, key}) => {
        if (key == FormType.create) {
            this.setState({isOpenCreateDrawer: true})
        }

        if (key == csv.downloadType) {
            csv.download(this.state.refundedBags, ['Date', 'Class', 'Quantity', 'Bag Type'], ['date', 'class', 'quantity', 'bagType'], `${this.props.orgInfo.name}-${moment().format('YYYY-MM-DD')}-refundedBags`)
        }
    };

    handleRecordModifyOnClick = (record) => {
        this.setState({isOpenUpdateDrawer: true, modifyFormData: record})
    };

    handleCUFormOnClose = () => {
        this.setState({isOpenCreateDrawer: false, isOpenUpdateDrawer: false})
    };

    handleDataChange = () => {
        this.refreshData();
        this.setState({isOpenCreateDrawer: false, isOpenUpdateDrawer: false})
    };

    refreshData = () => {
        this.props.setOrgPageIsLoading(true);
        this.constructor.RefundedBags(this.props.match.params.orgId).then((refundedBags) => {
            this.setState({refundedBags: refundedBags});
        }).catch((err) => {
            notification.warning({message: `Cannot refresh data`, description: `${err}`, duration: 0});
        }).finally(()=>{
            this.props.setOrgPageIsLoading(false);
        })
    };

    handleDeleteRecord = (record) => {
        this.props.setOrgPageIsLoading(true);
        return new ManagementHelperApi().managementHelperDeleteRefundedBag(record.id).then(() => {
            return null
        }).then(() => {
            notification.success({message: `${record.name} was successfully deleted`})
        }).catch((err) => {
            notification.warning({message: `Cannot delete ${record.date}`, description: `${err}`, duration: 0});
        }).finally(() => {
            this.props.setOrgPageIsLoading(false);
            this.refreshData();
        })
    };

    render() {
        const {orgInfo} = this.props;
        const {modifyFormData, isOpenCreateDrawer, isOpenUpdateDrawer, filteredValue, orgUsers, refundedBags} = this.state;
        const columns = [{
            title: 'Date',
            dataIndex: 'date',
            render: (date, record) => moment(date).format('YYYY-MM'),
            sorter: (a, b) => new Date(a.date) - new Date(b.date),
        }, {
            title: 'Class',
            dataIndex: 'userId',
            filter: {
                type: filterType.Checkbox,
                options: _(orgUsers).map('name').uniq().value(),
                onChange: (v) => {
                    let filterValue = [];
                    v.forEach((k) => {
                        const user = _.find(orgUsers, {name: k});
                        if (user) {
                            filterValue.push(user.id)
                        }
                    })
                    this.setState({filteredValue: Object.assign(this.state.filteredValue, {'class': filterValue})}
                    )
                }
            },
            filteredValue: filteredValue.class,
            onFilter: (value, record) => {
                return value == record.userId
            },
            sorter: (a, b) => {
                const userA = _.find(orgUsers, {id: a.userId});
                const userB = _.find(orgUsers, {id: b.userId});

                if (userA && userB) {
                    return userA.name.localeCompare(userB.name)
                }
                return false
            },
            render: (userId) => {
                const user = _.find(orgUsers, {id: userId});
                if (user) {
                    return user.name
                }
                return '-'
            }
        }, {
            title: 'Quantity',
            dataIndex: 'quantity',
            sorter: (a, b) => a.quantity - b.quantity,
        }, {
            title: 'Bag Type',
            dataIndex: 'bagType',
            sorter: (a, b) => a.bagType.localeCompare(b.bagType),
        }, OperationColumn(null, this.handleRecordModifyOnClick, this.handleDeleteRecord)];

        return <div>
            <DataTable operationBarComponent={<OperationBarComponent onClick={this.handleOperationBarOnClick}/>}
                       columns={columns}
                       data={refundedBags}
                       pageSize={50}
                       hideFilter={false}
                       rowKey="id"
            />

            {/* Create Form */}
            {isOpenCreateDrawer &&
            <CUForm
                title={'Create a new refunded bag record'}
                isOpen={isOpenCreateDrawer}
                formType={FormType.create}
                org={orgInfo}
                orgUsers={orgUsers}
                onClose={this.handleCUFormOnClose}
                onSuccess={this.handleDataChange}
            />}

            {/* Update Form */}
            {isOpenUpdateDrawer &&
            <CUForm
                title={'Modify a refunded bag record'}
                data={modifyFormData}
                org={orgInfo}
                orgUsers={orgUsers}
                isOpen={isOpenUpdateDrawer}
                formType={FormType.modify}
                onClose={this.handleCUFormOnClose}
                onSuccess={this.handleDataChange}
            />}

        </div>
    }
}

const OperationBarComponent = (props) => {
    return <Menu
        mode="horizontal"
        selectable={false}
        onClick={props.onClick}
    >
        <Menu.Item key={FormType.create}>
            <Icon type="plus"/>Create
        </Menu.Item>
        <Menu.Item key={csv.downloadType}>
            <Icon type="download" />Download
        </Menu.Item>
    </Menu>
};

export default OrgManagementRefundedBag;
