import React, {Component} from 'react'
import './PageMenu.scss'
import {Menu, Spin, Icon } from 'antd'

class PageMenu extends Component {
    // state = {
    //   loading: false,
    // };
    render() {
        return <div style={{position: 'relative'}}>
            <Menu
                mode="horizontal"
                className='page-menu'
                selectable={false}
                selectedKeys={[this.props.selected]}
                onClick={(a,b,c)=>{
                    if(this.props.onClick) {
                        // this.setState({loading: true});
                        this.props.onClick(a,b,c);
                    }
                }}
            >
                {this.props.children}
            </Menu>
            {/*<div style={{position: 'absolute', top:0, right:0, zIndex: 1001}}><Spin indicator={<Icon type="loading" style={{ fontSize: 24, color: '#fff', margin: '10px' }} spin />} spinning={this.state.loading} /></div>*/}
        </div>
    }
}

export default PageMenu;