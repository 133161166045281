import React, {Component} from 'react'
import {withPanel} from '~/src/layout/Panel'
import DataTable, {filterType} from "~/src/components/DataTable";
import TitleBar from "~/src/components/TitleBar";
import {Button, Menu, Icon, Drawer, Form, Input, Select, notification} from 'antd'

import ManagementOrgApi from "~/src/api-client/api/ManagementOrgApi";
import {FormType} from "~/src/utils/form";
import {code as langCode} from "~/src/utils/language";
import {OperationColumn} from '~/src/utils/data-table'
import LoadingBar from '~/src/components/LoadingBar'
import {connect} from "react-redux";
import {fetchCurrentOrg, logout, resetOrgPageIsLoading, setOrgPageIsLoading} from "~/src/redux/action";

@connect(
    (state) => {
        return {
            init: state.init,
            userInfo: state.user,
        }
    },
    (dispatch, ownProps) => {
        return {
            logout: ()=>{
                return dispatch(logout())
            }
        }
    }
)
class Org extends Component {
    state = {
        isLoading: true,
        filteredValue: {},
        result: {orgs: []},
        isOpenCreateDrawer: false,
        isOpenUpdateDrawer: false,
        modifyFormData: {}
    };

    componentDidMount(){
        if(this.props.init == true) {
            this.refreshData();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.init == false && this.props.init ==true) {
            this.refreshData();
        }
    }

    static Data() {
        return new ManagementOrgApi().managementOrgs().then((data) => {
            return data.orgs
        })
    }

    constructor(props) {
        super(props);
    }

    handleOperationBarOnClick = ({item, key}) => {
        if (key == FormType.create) {
            this.setState({isOpenCreateDrawer: true})
        }
    };

    handleRecordModifyOnClick = (record) => {
        this.setState({isOpenUpdateDrawer: true, modifyFormData: record})
    };

    handleRecordManagementBtnOnClick = (record) => {
        if(this.props.userInfo.role == 'admin') {
            this.props.history.push({pathname: `/management/org/${record.id}/user`})
        } else if (this.props.userInfo.role == 'helper'){
            this.props.history.push({pathname: `/management/org/${record.id}/usage/waste`})
        }
    };

    handleCUFormOnClose = () => {
        this.setState({isOpenCreateDrawer: false, isOpenUpdateDrawer: false})
    };

    handleDataChange = () => {
        this.refreshData();
        this.setState({isOpenCreateDrawer: false, isOpenUpdateDrawer: false})
    };

    refreshData = () => {
        this.setState({isLoading: true})
        this.constructor.Data().then((orgs) => {
            let newResult = {...this.state.result};
            newResult.orgs = orgs;
            this.setState({result: newResult});
        }).catch((err) => {
            notification.warning({message: `Cannot refresh data`, description: `${err}`, duration: 0});
        }).finally(()=>{
            this.setState({isLoading: false})
        })
    };

    handleDeleteRecord = (record) => {
        this.setState({isLoading: true})
        return new ManagementOrgApi().managementOrgDelete(record.id).then((data) => {
            notification.success({message: `${record.name} was successfully deleted`})
        }).catch((err) => {
            notification.warning({message: `Cannot delete ${record.name}`, description: `${err}`, duration: 0});
        }).finally(() => {
            this.refreshData();
        }).finally(()=>{
            this.setState({isLoading: false})
        })
    };

    render() {
        const {modifyFormData, isOpenCreateDrawer, isOpenUpdateDrawer, result, isLoading} = this.state;
        const {init, userInfo, logout} = this.props;

        if(!init){
            return <LoadingBar show={true} />
        }

        if(userInfo.role != 'admin' && userInfo.role != 'helper' ) {
            this.props.logout()
            return <LoadingBar show={true} />
        }

        let columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                sorter: (a, b) => a.name.localeCompare(b.name),
            }, {
                title: 'Abbreviation',
                dataIndex: 'abbv',
                sorter: (a, b) => a.abbv.localeCompare(b.abbv),
            }, {
                title: 'Default language',
                dataIndex: 'defaultLangCode',
                render: text => langCode[text] ? langCode[text] : '-',
                sorter: (a, b) => a.createdAt - b.createdAt,
            }, {
                title: 'Max play per day',
                dataIndex: 'maxMcPlayPerDay',
                sorter: (a, b) => a.maxMcPlayPerDay - b.maxMcPlayPerDay,
            }, {
                title: 'Created at',
                dataIndex: 'createdAt',
                render: text => new Date(text).toLocaleString(),
                sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
            },
            (()=> {
                if(this.props.userInfo.role == 'admin') {
                    return OperationColumn(this.handleRecordManagementBtnOnClick, this.handleRecordModifyOnClick, this.handleDeleteRecord)
                } else if (this.props.userInfo.role == 'helper'){
                    return OperationColumn(this.handleRecordManagementBtnOnClick)
                }
            })(),
        ];

        return <div>
            <TitleBar title='School Management'/>
            <LoadingBar show={isLoading} />
            <DataTable operationBarComponent={<OperationBarComponent onClick={this.handleOperationBarOnClick}/>}
                       columns={columns}
                       data={result.orgs}
                       pageSize={50}
                       hideFilter={true}
                       rowKey="id"
            />

            {/* Create Form */}
            {isOpenCreateDrawer &&
            <CUForm
                title={'Create a new school'}
                isOpen={isOpenCreateDrawer}
                formType={FormType.create}
                onClose={this.handleCUFormOnClose}
                onSuccess={this.handleDataChange}
            />}

            {/* Update Form */}
            {isOpenUpdateDrawer &&
            <CUForm
                title={'Modify a school'}
                data={modifyFormData}
                isOpen={isOpenUpdateDrawer}
                formType={FormType.modify}
                onClose={this.handleCUFormOnClose}
                onSuccess={this.handleDataChange}
            />}

        </div>
    }
}

const OperationBarComponent = (props) => {
    return <Menu
        mode="horizontal"
        selectable={false}
        onClick={props.onClick}
    >
        <Menu.Item key={FormType.create}>
            <Icon type="plus"/>Create
        </Menu.Item>
    </Menu>
};

// Create and Update Form
@Form.create()
class CUForm extends Component {
    static defaultProps = {
        formType: FormType.create,
        data: {
            id: '',
            name: '',
            abbv: '',
            defaultLangCode: 'tc',
        }
    };

    create = (data) => {
        data.maxMcPlayPerDay = parseInt(data.maxMcPlayPerDay);
        new ManagementOrgApi().managementOrgCreate(data).then(() => {
            if (this.props.onSuccess) {
                this.props.onSuccess()
            }
            notification.success({message: `${data.name} was successfully created`})
        }).catch((err) => {
            notification.warning({message: `Cannot create ${data.name}`, description: `${err}`, duration: 0});
        })
    }

    modify = (data) => {
        data.maxMcPlayPerDay = parseInt(data.maxMcPlayPerDay);
        new ManagementOrgApi().managementOrgUpdate(this.props.data.id, data).then(() => {
            if (this.props.onSuccess) {
                this.props.onSuccess()
            }
            notification.success({message: `${data.name} was successfully modified`})
        }).catch((err) => {
            notification.warning({
                message: `Cannot modify ${this.props.data.name}`,
                description: `${err}`,
                duration: 0
            });
        })
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (err) {
                return;
            }

            console.log('Received values of form: ', values);
            this[this.props.formType](values)
        });
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {name, abbv, address, defaultLangCode, maxMcPlayPerDay} = this.props.data;

        return <Drawer
            title={this.props.title}
            width={720}
            onClose={this.props.onClose}
            visible={this.props.isOpen}
        >
            <Form layout="vertical" onSubmit={this.handleSubmit}>
                <Form.Item label="Name" hasFeedback colon={false}>
                    {getFieldDecorator('name', {rules: [{required: true}], initialValue: name})(
                        <Input type="text"/>
                    )}
                </Form.Item>
                <Form.Item label="Abbreviation" hasFeedback colon={false}>
                    {getFieldDecorator('abbv', {initialValue: abbv, rules: [{required: true}]})(
                        <Input type="text"/>
                    )}
                </Form.Item>
                <Form.Item label="Default language" hasFeedback colon={false}>
                    {getFieldDecorator('defaultLangCode', {
                        rules: [{required: true}],
                        initialValue: defaultLangCode
                    })(
                        <Select>
                            <Select.Option value="tc">Traditional Chinese</Select.Option>
                            <Select.Option value="en">English</Select.Option>
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label="Address" hasFeedback colon={false}>
                    {getFieldDecorator('address', {initialValue: address, rules: [{required: true}]})(
                        <Input.TextArea rows={4} />
                    )}
                </Form.Item>
                <Form.Item label="Max play per day" hasFeedback colon={false}>
                    {getFieldDecorator('maxMcPlayPerDay', {initialValue: maxMcPlayPerDay, rules: [{required: false}]})(
                        <Input type="number"/>
                    )}
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">Submit</Button>
                </Form.Item>
            </Form>
        </Drawer>
    }
}

export default Org;
