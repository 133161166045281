
// Create and Update Form
import {Button, DatePicker, Drawer, Form, Input, notification, Select} from "antd";
import React, {Component} from "react";
import {FormType} from "~/src/utils/form";
import moment from "moment";
import {ManagementOrgBagUsageApi} from "~/src/api-client";
import _ from 'lodash'

@Form.create()
class CUForm extends Component {
    static defaultProps = {
        formType: FormType.create,
        data: {

        }
    };

    state = {
        haveShopper: false,
    }

    create = (data) => {
        return Promise.all(_.map(this.props.itemList, item => {
            if (data[item] !== 0) {
                return new ManagementOrgBagUsageApi().managementOrgBagUsageCreate(this.props.org.id, {
                    bagType: item,
                    quantity: parseInt(data[item]),
                    date: moment(data.date).startOf('day').toDate(),
                });
            }
        }))
        // return new ManagementOrgBagUsageApi().managementOrgBagUsageCreate(this.props.org.id, {
        //     // userId: parseInt(data.userId),
        //     bagType: data.bagType,
        //     quantity: parseInt(data.quantity),
        //     date: moment(data.date).set({ hour: 0, minute: 0 }).toDate(),
        // })
        .then(() => {
            if (this.props.onSuccess) {
                this.props.onSuccess()
            }
            notification.success({message: `The new record was successfully created`})
        }).catch((err) => {
            notification.warning({message: `Cannot create ${data.name}`, description: `${err}`, duration: 0});
        });
    };

    modify = (data) => {
        const targetBagUsages = _.filter(this.props.bagUsages, order => moment(order.date).format('YYYY-MM-DD') === this.props.data.date);
        const updatePromises = _.map(targetBagUsages, order => {
            if (data[order.bagType] === 0) {
                return new ManagementOrgBagUsageApi().managementOrgBagUsageDelete(this.props.org.id, order.id);
            } else {
                return new ManagementOrgBagUsageApi().managementOrgBagUsageUpdate(this.props.org.id, order.id, {
                    bagType: order.bagType,
                    quantity: parseInt(data[order.bagType]),
                    date: moment(data.date).startOf('day').toDate(),
                });
            }
        });
        const createPromises = _.map(this.props.itemList, item => {
            if ((_.findIndex(targetBagUsages, order => order.bagType === item) === -1) && data[item] !== 0) {
                return new ManagementOrgBagUsageApi().managementOrgBagUsageCreate(this.props.org.id, {
                    bagType: item,
                    quantity: parseInt(data[item]),
                    date: moment(data.date).startOf('day').toDate(),
                });
            }
        })
        return Promise.all(_.concat(updatePromises, createPromises))
        // return new ManagementOrgBagUsageApi().managementOrgBagUsageUpdate(this.props.org.id, this.props.data.id, {
        //     // userId: parseInt(data.userId),
        //     bagType: data.bagType,
        //     quantity: parseInt(data.quantity),
        //     date: moment(data.date).set({ hour: 0, minute: 0 }).toDate(),
        // })
        .then(() => {
            if (this.props.onSuccess) {
                this.props.onSuccess()
            }
            notification.success({message: `The record was successfully modified`})
        }).catch((err) => {
            notification.warning({
                message: `Cannot modify  ${this.props.data.name}`,
                description: `${err}`,
                duration: 0
            });
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (err) {
                return;
            }

            console.log('Received values of form: ', values);
            this[this.props.formType](values)
        });
    }

    componentDidMount() {
        // Class list
    }

    render() {
        // Order Date
        // Class
        // Item
        // Qty
        // Revised Qty
        // Status
        const {getFieldDecorator} = this.props.form;
        const {userId, bagType , quantity, date, ...rest} = this.props.data;
        const {orgUsers, itemList} = this.props;

        return <Drawer
            title={this.props.title}
            width={720}
            onClose={this.props.onClose}
            visible={this.props.isOpen}
        >
            <Form layout="vertical" onSubmit={this.handleSubmit}>
                <Form.Item label="Order Date" hasFeedback colon={false}>
                    {getFieldDecorator('date', {rules: [{required: true}], initialValue: date ? moment(date): null})(
                        <DatePicker />
                    )}
                </Form.Item>
                {/*<Form.Item label="Class / School" hasFeedback colon={false}>*/}
                {/*    {getFieldDecorator('userId', {rules: [{required: true}], initialValue: userId ? userId: null})(*/}
                {/*        <Select>*/}
                {/*            {*/}
                {/*                orgUsers.map((v, i) => {*/}
                {/*                    console.log(v)*/}
                {/*                    return <Select.Option key={i} value={v.id}>{v.name}</Select.Option>*/}
                {/*                })*/}
                {/*            }*/}
                {/*        </Select>*/}
                {/*    )}*/}
                {/*</Form.Item>*/}
                {
                    _.map(itemList, (item, i) => (
                        <Form.Item key={item} label={item} hasFeedback colon={false}>
                            {getFieldDecorator(item, {initialValue: rest[item] ? rest[item]: 0})(
                                <Input type="number" min={0}/>
                            )}
                        </Form.Item>
                    ))
                }

                <Form.Item>
                    <Button type="primary" htmlType="submit">Submit</Button>
                </Form.Item>
            </Form>
        </Drawer>
    }
}

export default CUForm;