/**
 * Big Waster API
 * This is a Big Waster API.
 *
 * OpenAPI spec version: 1.0
 * Contact: terry.wong@futureimpactlab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ItemCreateItemParam from '../model/ItemCreateItemParam';
import ItemOutputCmsItem from '../model/ItemOutputCmsItem';
import ItemOutputCmsItems from '../model/ItemOutputCmsItems';
import ItemUpdateItemParam from '../model/ItemUpdateItemParam';
import OrderCreateOrderParam from '../model/OrderCreateOrderParam';
import OrderOutputCmsOrder from '../model/OrderOutputCmsOrder';
import OrderOutputCmsOrders from '../model/OrderOutputCmsOrders';
import OrderUpdateOrderParam from '../model/OrderUpdateOrderParam';
import OrderUpdateOrderStatusParam from '../model/OrderUpdateOrderStatusParam';
import UtilHttpErrorBody from '../model/UtilHttpErrorBody';
import UtilHttpOkBody from '../model/UtilHttpOkBody';
import UtilHttpRowAffBody from '../model/UtilHttpRowAffBody';

/**
* ManagementShop service.
* @module api/ManagementShopApi
* @version 1.0
*/
export default class ManagementShopApi {

    /**
    * Constructs a new ManagementShopApi. 
    * @alias module:api/ManagementShopApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Create shop item
     * @param {module:model/ItemCreateItemParam} itemCreateItemParam Create item parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UtilHttpOkBody} and HTTP response
     */
    managementShopCreateItemWithHttpInfo(itemCreateItemParam) {
      let postBody = itemCreateItemParam;

      // verify the required parameter 'itemCreateItemParam' is set
      if (itemCreateItemParam === undefined || itemCreateItemParam === null) {
        throw new Error("Missing the required parameter 'itemCreateItemParam' when calling managementShopCreateItem");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = UtilHttpOkBody;

      return this.apiClient.callApi(
        '/management/shop/item', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create shop item
     * @param {module:model/ItemCreateItemParam} itemCreateItemParam Create item parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UtilHttpOkBody}
     */
    managementShopCreateItem(itemCreateItemParam) {
      return this.managementShopCreateItemWithHttpInfo(itemCreateItemParam)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create shop order
     * @param {module:model/OrderCreateOrderParam} orderCreateOrderParam Create order parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UtilHttpOkBody} and HTTP response
     */
    managementShopCreateOrderWithHttpInfo(orderCreateOrderParam) {
      let postBody = orderCreateOrderParam;

      // verify the required parameter 'orderCreateOrderParam' is set
      if (orderCreateOrderParam === undefined || orderCreateOrderParam === null) {
        throw new Error("Missing the required parameter 'orderCreateOrderParam' when calling managementShopCreateOrder");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = UtilHttpOkBody;

      return this.apiClient.callApi(
        '/management/shop/order', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create shop order
     * @param {module:model/OrderCreateOrderParam} orderCreateOrderParam Create order parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UtilHttpOkBody}
     */
    managementShopCreateOrder(orderCreateOrderParam) {
      return this.managementShopCreateOrderWithHttpInfo(orderCreateOrderParam)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete shop item
     * @param {Number} itemId item id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UtilHttpRowAffBody} and HTTP response
     */
    managementShopDeleteItemWithHttpInfo(itemId) {
      let postBody = null;

      // verify the required parameter 'itemId' is set
      if (itemId === undefined || itemId === null) {
        throw new Error("Missing the required parameter 'itemId' when calling managementShopDeleteItem");
      }


      let pathParams = {
        'itemId': itemId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = UtilHttpRowAffBody;

      return this.apiClient.callApi(
        '/management/shop/item/{itemId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete shop item
     * @param {Number} itemId item id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UtilHttpRowAffBody}
     */
    managementShopDeleteItem(itemId) {
      return this.managementShopDeleteItemWithHttpInfo(itemId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete shop order
     * @param {Number} orderId order id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UtilHttpRowAffBody} and HTTP response
     */
    managementShopDeleteOrderWithHttpInfo(orderId) {
      let postBody = null;

      // verify the required parameter 'orderId' is set
      if (orderId === undefined || orderId === null) {
        throw new Error("Missing the required parameter 'orderId' when calling managementShopDeleteOrder");
      }


      let pathParams = {
        'orderId': orderId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = UtilHttpRowAffBody;

      return this.apiClient.callApi(
        '/management/shop/order/{orderId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete shop order
     * @param {Number} orderId order id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UtilHttpRowAffBody}
     */
    managementShopDeleteOrder(orderId) {
      return this.managementShopDeleteOrderWithHttpInfo(orderId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get specific shop item
     * @param {Number} itemId item id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ItemOutputCmsItem} and HTTP response
     */
    managementShopItemWithHttpInfo(itemId) {
      let postBody = null;

      // verify the required parameter 'itemId' is set
      if (itemId === undefined || itemId === null) {
        throw new Error("Missing the required parameter 'itemId' when calling managementShopItem");
      }


      let pathParams = {
        'itemId': itemId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ItemOutputCmsItem;

      return this.apiClient.callApi(
        '/management/shop/item/{itemId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get specific shop item
     * @param {Number} itemId item id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ItemOutputCmsItem}
     */
    managementShopItem(itemId) {
      return this.managementShopItemWithHttpInfo(itemId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get all items
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ItemOutputCmsItems} and HTTP response
     */
    managementShopItemsWithHttpInfo() {
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ItemOutputCmsItems;

      return this.apiClient.callApi(
        '/management/shop/item', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get all items
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ItemOutputCmsItems}
     */
    managementShopItems() {
      return this.managementShopItemsWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get specific shop order
     * @param {Number} orderId order id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/OrderOutputCmsOrder} and HTTP response
     */
    managementShopOrderWithHttpInfo(orderId) {
      let postBody = null;

      // verify the required parameter 'orderId' is set
      if (orderId === undefined || orderId === null) {
        throw new Error("Missing the required parameter 'orderId' when calling managementShopOrder");
      }


      let pathParams = {
        'orderId': orderId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = OrderOutputCmsOrder;

      return this.apiClient.callApi(
        '/management/shop/order/{orderId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get specific shop order
     * @param {Number} orderId order id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/OrderOutputCmsOrder}
     */
    managementShopOrder(orderId) {
      return this.managementShopOrderWithHttpInfo(orderId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get all shop order
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/OrderOutputCmsOrders} and HTTP response
     */
    managementShopOrdersWithHttpInfo() {
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = OrderOutputCmsOrders;

      return this.apiClient.callApi(
        '/management/shop/order', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get all shop order
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/OrderOutputCmsOrders}
     */
    managementShopOrders() {
      return this.managementShopOrdersWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update shop item
     * @param {Number} itemId item id
     * @param {module:model/ItemUpdateItemParam} itemUpdateItemParam Update item parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UtilHttpRowAffBody} and HTTP response
     */
    managementShopUpdateItemWithHttpInfo(itemId, itemUpdateItemParam) {
      let postBody = itemUpdateItemParam;

      // verify the required parameter 'itemId' is set
      if (itemId === undefined || itemId === null) {
        throw new Error("Missing the required parameter 'itemId' when calling managementShopUpdateItem");
      }

      // verify the required parameter 'itemUpdateItemParam' is set
      if (itemUpdateItemParam === undefined || itemUpdateItemParam === null) {
        throw new Error("Missing the required parameter 'itemUpdateItemParam' when calling managementShopUpdateItem");
      }


      let pathParams = {
        'itemId': itemId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = UtilHttpRowAffBody;

      return this.apiClient.callApi(
        '/management/shop/item/{itemId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update shop item
     * @param {Number} itemId item id
     * @param {module:model/ItemUpdateItemParam} itemUpdateItemParam Update item parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UtilHttpRowAffBody}
     */
    managementShopUpdateItem(itemId, itemUpdateItemParam) {
      return this.managementShopUpdateItemWithHttpInfo(itemId, itemUpdateItemParam)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update shop order
     * @param {Number} orderId order id
     * @param {module:model/OrderUpdateOrderParam} orderUpdateOrderParam Update order parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UtilHttpRowAffBody} and HTTP response
     */
    managementShopUpdateOrderWithHttpInfo(orderId, orderUpdateOrderParam) {
      let postBody = orderUpdateOrderParam;

      // verify the required parameter 'orderId' is set
      if (orderId === undefined || orderId === null) {
        throw new Error("Missing the required parameter 'orderId' when calling managementShopUpdateOrder");
      }

      // verify the required parameter 'orderUpdateOrderParam' is set
      if (orderUpdateOrderParam === undefined || orderUpdateOrderParam === null) {
        throw new Error("Missing the required parameter 'orderUpdateOrderParam' when calling managementShopUpdateOrder");
      }


      let pathParams = {
        'orderId': orderId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = UtilHttpRowAffBody;

      return this.apiClient.callApi(
        '/management/shop/order/{orderId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update shop order
     * @param {Number} orderId order id
     * @param {module:model/OrderUpdateOrderParam} orderUpdateOrderParam Update order parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UtilHttpRowAffBody}
     */
    managementShopUpdateOrder(orderId, orderUpdateOrderParam) {
      return this.managementShopUpdateOrderWithHttpInfo(orderId, orderUpdateOrderParam)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update shop&#39;s order status
     * @param {Number} orderId order id
     * @param {module:model/OrderUpdateOrderStatusParam} orderUpdateOrderStatusParam Update order parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UtilHttpRowAffBody} and HTTP response
     */
    managementShopUpdateOrderStatusWithHttpInfo(orderId, orderUpdateOrderStatusParam) {
      let postBody = orderUpdateOrderStatusParam;

      // verify the required parameter 'orderId' is set
      if (orderId === undefined || orderId === null) {
        throw new Error("Missing the required parameter 'orderId' when calling managementShopUpdateOrderStatus");
      }

      // verify the required parameter 'orderUpdateOrderStatusParam' is set
      if (orderUpdateOrderStatusParam === undefined || orderUpdateOrderStatusParam === null) {
        throw new Error("Missing the required parameter 'orderUpdateOrderStatusParam' when calling managementShopUpdateOrderStatus");
      }


      let pathParams = {
        'orderId': orderId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = UtilHttpRowAffBody;

      return this.apiClient.callApi(
        '/management/shop/order/{orderId}/status', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update shop&#39;s order status
     * @param {Number} orderId order id
     * @param {module:model/OrderUpdateOrderStatusParam} orderUpdateOrderStatusParam Update order parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UtilHttpRowAffBody}
     */
    managementShopUpdateOrderStatus(orderId, orderUpdateOrderStatusParam) {
      return this.managementShopUpdateOrderStatusWithHttpInfo(orderId, orderUpdateOrderStatusParam)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
