import {Button, Popconfirm} from 'antd'
import React from 'react'

export function OperationColumn(onManagementClick, onModifyClick, onDeleteClick) {
    return {
        title: 'Operations',
        dataIndex: '',
        render: (text, record) => <Button.Group>
            {
                onManagementClick &&
                <Button type="primary" onClick={()=>{onManagementClick(record)}}>Manage</Button>
            }
            {
                onModifyClick &&
                <Button onClick={()=>{onModifyClick(record)}}>Modify</Button>
            }
            {
                onDeleteClick &&
                <Popconfirm duration={0} title="Are you sure delete this?" onConfirm={()=>{onDeleteClick(record)}} okText="Yes" cancelText="No">
                    <Button type="danger">Delete</Button>
                </Popconfirm>
            }
        </Button.Group>,
    }
}
