import React, {Component} from 'react'
import DataTable, {filterType} from "~/src/components/DataTable";

import {Button, Menu, Icon, Drawer, Form, Input, DatePicker, notification, Dropdown, Select} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import ShopApi from "~/src/api-client/api/ManagementShopApi"

import {FormType} from "~/src/utils/form"
import {OperationColumn} from '~/src/utils/data-table'

import ManagementUserApi from "~/src/api-client/api/ManagementUserApi";
import csv from "~/src/csv";
import CUForm from "./Form";

const MenuKeyConfirmAllPendingOrder = 'MenuKeyConfirmAllPendingOrder';

// @compose(
//     connect((state, props) => {
//             return {
//                 org: state.currentOrg,
//             }
//         }
//     ),
//     withPanel,
//     wrapper({selectedMenuItem: 'shop-order'}),
//     newInitFetch([
//         {
//             name: 'orders',
//             fn: (component, router) => {
//                 return component.OrderList(router.query.orgId)
//             }
//         },
//         {
//             name: 'orgUsers',
//             fn: (component, router) => {
//                 return component.OrgUsers(router.query.orgId)
//             }
//         },
//         {
//             name: 'itemList',
//             fn: (component, router) => {
//                 return component.ShopItemList()
//             }
//         }
//     ])
// )
class OrgManagementShopOrder extends Component {
    state = {
        filteredValue: {},
        orders: [],
        orgUsers: [],
        itemList:[],
        isOpenCreateDrawer: false,
        isOpenUpdateDrawer: false,
        modifyFormData: {}
    };

    statusBtnColor = {
        pending: '#efefef',
        canceled: '#ffb6b0',
        confirmed: '#acffd7',
    }

    statusBtnValue = {
        pending: <span><Icon type="minus"/> Pending</span>,
        canceled: <span><Icon type="close" style={{color: 'red'}}/> Canceled</span>,
        confirmed: <span><Icon type="check" style={{color: 'green'}}/> Confirmed</span>,
    };
    //
    // static getDerivedStateFromProps(props, state) {
    //     // get result
    //     if (!state.result) {
    //         let newResult = {...state.result};
    //         newResult.orders = props.orders;
    //         newResult.org = props.org;
    //         newResult.orgUsers = props.orgUsers;
    //         newResult.itemList = props.itemList;
    //         return {
    //             result: newResult
    //         };
    //     }
    //     return null;
    // }

    static OrderList(orgId) {
        return new ShopApi().managementShopOrders().then(({orders}) => {
            return orders ? orders.filter((v) => {
                return v.user.org.rowId == orgId
            }) : [];
        })
    }

    static ShopItemList() {
        return new ShopApi().managementShopItems().then(({items}) => {
            return items;
        })
    }

    static OrgUsers(orgId) {
        return new ManagementUserApi().managementUsers({org: orgId}).then(({users: allUsers}) => {
            let users = _(allUsers).filter((v) => v.role == 'user' || v.role == 'org').value();
            return users;
        })
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.constructor.ShopItemList().then((itemList)=>{
            this.setState({itemList: itemList})
        });

        this.constructor.OrgUsers(this.props.orgInfo.id).then((orgUsers)=>{
            this.setState({orgUsers: orgUsers})
        });

        this.refreshData()
    }

    handleOperationBarOnClick = ({item, key}) => {
        if (key == FormType.create) {
            this.setState({isOpenCreateDrawer: true})
        }

        if(key == MenuKeyConfirmAllPendingOrder) {
            this.confirmAllPendingOrder(this.state.orders)
        }

        if (key == csv.downloadType) {
            csv.download(this.state.orders.map((v, k)=>{
                v.date = moment(v.date).format('YYYY-MM-DD');
                return v
            }), ['Order date', 'Class / School', 'Item', 'Qty', 'Revised Qty', 'Status'], ['date', 'user.name', 'items[0].name', 'items[0].quantity', 'items[0].revisedQuantity', 'status'], `${this.props.org.name}-${moment().format('YYYY-MM-DD')}-shopOrders`)
        }
    };

    confirmAllPendingOrder = (records) => {
        let promises = [];
        records.forEach((record)=>{
            if(record.status == 'pending'){
                promises.push(new ShopApi().managementShopUpdateOrderStatus(record.id, {status: "confirmed"}))
            }
        })

        Promise.all(promises).then(() => {
            notification.info({message: `Loading...`});
        }).catch((err) => {
        }).finally(() => {
            notification.destroy()
            notification.success({message: `Orders was successfully updated`});
            this.refreshData();
        })
    };

    handleRecordModifyOnClick = (record) => {
        this.setState({isOpenUpdateDrawer: true, modifyFormData: record})
    };

    handleCUFormOnClose = () => {
        this.setState({isOpenCreateDrawer: false, isOpenUpdateDrawer: false})
    };

    handleDataChange = () => {
        this.refreshData();
        this.setState({isOpenCreateDrawer: false, isOpenUpdateDrawer: false})
    };

    refreshData = () => {
        this.props.setOrgPageIsLoading(true);
        this.constructor.OrderList(this.props.orgInfo.id).then((orders) => {
            this.setState({orders: orders});
        }).catch((err) => {
            notification.warning({message: `Cannot refresh data`, description: `${err}`, duration: 0});
        }).finally(()=>{
            this.props.setOrgPageIsLoading(false);
        })
    };

    handleDeleteRecord = (record) => {
        this.props.setOrgPageIsLoading(true);
        return new ShopApi().managementShopDeleteOrder(record.id).then(() => {
            notification.success({message: `Order ${record.user.name} - ${moment(record.date).format('YYYY-MM-DD')} was successfully deleted`})
        }).catch((err) => {
            notification.warning({message: `Cannot delete order ${record.user.name} - ${record.date} `, description: `${err}`, duration: 0});
        }).finally(() => {
            this.props.setOrgPageIsLoading(false);
            this.refreshData();
        })
    };

    handleUpdateRecord = (record) =>{
        return new ShopApi().managementShopUpdateOrderStatus(record.id, {status: record.status}).then(() => {
            notification.success({message: `Order ${record.user.name} - ${moment(record.date).format('YYYY-MM-DD')} was successfully updated`})
        }).catch((err) => {
            notification.warning({message: `Cannot update order ${record.user.name} - ${record.date} `, description: `${err}`, duration: 0});
        }).finally(() => {
            this.refreshData();
        })
    };

    render() {
        const {orgInfo} = this.props;

        const {modifyFormData,
            isOpenCreateDrawer,
            isOpenUpdateDrawer,
            result,
            filteredValue,
            itemList,
            orgUsers,
            orders} = this.state;

        let columns = [
            // {
            //     title: 'Username',
            //     dataIndex: 'username',
            //     sorter: (a, b) => a.abbv.localeCompare(b.abbv),
            // },
            {
                title: 'Order Date',
                dataIndex: 'date',
                render: v => moment(v).format('YYYY-MM-DD'),
                sorter: (a, b) => {return moment(a.date) - moment(b.date)},
            },
            {
                title: 'Class / School',
                dataIndex: 'user.name',
                filter: {
                    title: 'Class / School',
                    type: filterType.Checkbox,
                    options: _(orders).map('user.name').uniq().value().sort(),
                    onChange: (v) => {
                        this.setState({filteredValue: Object.assign(filteredValue, {name: v})})
                    }
                },
                filteredValue: filteredValue.name,
                onFilter: (value, record) => {
                    return value == record.user.name
                },
                sorter: (a, b) => a.user.name.localeCompare(b.user.name),
            }, {
                title: 'Item',
                dataIndex: 'items[0].name',
                sorter: (a, b) => a.items[0].name.localeCompare(b.items[0].name),
            }, {
                title: 'Qty',
                dataIndex: 'items[0].quantity',
                sorter: (a, b) => a > b,
            }, {
                title: 'Revised Qty',
                dataIndex: 'items[0].revisedQuantity',
                sorter: (a, b) => a > b,
            }, {
                title: 'Status',
                dataIndex: 'status',
                sorter: (a, b) => a.status.localeCompare(b.status),
                filter: {
                    title: 'Status',
                    type: filterType.Checkbox,
                    options: _(orders).map('status').uniq().value().sort(),
                    onChange: (v) => {
                        this.setState({filteredValue: Object.assign(filteredValue, {status: v})})
                    }
                },
                onFilter: (value, record) => {
                    return value == record.status
                },
                filteredValue: filteredValue.status,
                render: (v, record) => {
                    return <Dropdown overlay={<Menu onClick={({key}) => {
                        record.status = key
                        this.handleUpdateRecord(record)
                    }}>
                        <Menu.Item key="pending">{this.statusBtnValue.pending}</Menu.Item>
                        <Menu.Item key="canceled">{this.statusBtnValue.canceled}</Menu.Item>
                        <Menu.Item key="confirmed">{this.statusBtnValue.confirmed}</Menu.Item>
                    </Menu>} trigger={['click']} placement="topLeft">
                        <Button size="small" style={{background: this.statusBtnColor[v]}}>
                            {this.statusBtnValue[v]}
                            <Icon type="down"/>
                        </Button>
                    </Dropdown>
                }
            }, OperationColumn(null, this.handleRecordModifyOnClick, this.handleDeleteRecord)
        ];

        return <div>
            <DataTable operationBarComponent={<OperationBarComponent onClick={this.handleOperationBarOnClick}/>}
                       columns={columns}
                       data={orders}
                       pageSize={50}
                       hideFilter={false}
                       rowKey="id"
            />

            {/* Create Form */}
            {isOpenCreateDrawer &&
            <CUForm
                title={'Create a new order'}
                isOpen={isOpenCreateDrawer}
                formType={FormType.create}
                org={orgInfo}
                orgUsers={orgUsers}
                itemList={itemList}
                onClose={this.handleCUFormOnClose}
                onSuccess={this.handleDataChange}
            />}

            {/* Update Form */}
            {isOpenUpdateDrawer &&
            <CUForm
                title={'Modify a order'}
                data={modifyFormData}
                org={orgInfo}
                orgUsers={orgUsers}
                itemList={itemList}
                isOpen={isOpenUpdateDrawer}
                formType={FormType.modify}
                onClose={this.handleCUFormOnClose}
                onSuccess={this.handleDataChange}
            />}

        </div>
    }
}

const OperationBarComponent = (props) => {
    return <Menu
        mode="horizontal"
        selectable={false}
        onClick={props.onClick}
    >
        <Menu.Item key={FormType.create}>
            <Icon type="plus"/>Create
        </Menu.Item>
        <Menu.Item key={MenuKeyConfirmAllPendingOrder}>
            <Icon type="check-circle" />Confirm All Pending Orders
        </Menu.Item>
        <Menu.Item key={csv.downloadType}>
            <Icon type="download"/>Download
        </Menu.Item>
    </Menu>
};

export default OrgManagementShopOrder;
