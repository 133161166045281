
// Create and Update Form
import {Button, Checkbox, Drawer, Form, Input, notification} from "antd";
import React, {Component} from "react";
import {FormType} from "~/src/utils/form";
import ManagementUserApi from "~/src/api-client/api/ManagementUserApi";

@Form.create()
class CUForm extends Component {
    static defaultProps = {
        formType: FormType.create,
        org: {id: -1, name: ''},
        data: {
            username: '',
            name: '',
            haveShopper: false,
            shopperId: null,
        }
    };

    state = {
        haveShopper: false,
    }

    create = (data) => {
        const baseUsername = `${this.props.org.abbv}_${data.name.toLowerCase()}`;
        // Create User
        new ManagementUserApi().managementCreateUser({
            username: baseUsername,
            name: `${data.name.toUpperCase()}`,
            password: 'user',
            role: 'user',
            orgId: this.props.org.id,
            parentUserId: null,
        }).then(({id}) => {
            // Create Shopper
            if (this.state.haveShopper) {
                return new ManagementUserApi().managementCreateUser({
                    username: `${baseUsername}_shopper`,
                    name: `${data.name.toUpperCase()}`,
                    password: data.shopperPassword,
                    role: 'shopper',
                    orgId: this.props.org.id,
                    parentUserId: id,
                });
            }
            return null;
        }).then(() => {
            if (this.props.onSuccess) {
                this.props.onSuccess()
            }
            notification.success({message: `${data.name} was successfully created`})
        }).catch((err) => {
            notification.warning({message: `Cannot create ${data.name}`, description: `${err}`, duration: 0});
        })
    };

    modify = (data) => {
        const baseUsername = `${this.props.org.abbv}_${data.name.toLowerCase()}`;
        // Update User
        new ManagementUserApi().managementUpdateUser(this.props.data.id, {
            username: baseUsername,
            name: `${data.name.toUpperCase()}`,
            role: 'user',
            orgId: this.props.org.id,
            parentUserId: null,
        }).then(() => {
            if (this.state.haveShopper && !this.props.data.haveShopper) {
                // Create Shopper
                return new ManagementUserApi().managementCreateUser({
                    username: `${baseUsername}_shopper`,
                    name: `${data.name.toUpperCase()}`,
                    password: data.shopperPassword,
                    role: 'shopper',
                    orgId: this.props.org.id,
                    parentUserId: this.props.data.id,
                });
            } else if (this.state.haveShopper && this.props.data.haveShopper) {
                // Update user
                return new ManagementUserApi().managementUpdateUser(this.props.data.shopperId, {
                    username: `${baseUsername}_shopper`,
                    name: `${data.name.toUpperCase()}`,
                    password: data.shopperPassword ? data.shopperPassword: null,
                    role: 'shopper',
                    orgId: this.props.org.id,
                    parentUserId: this.props.data.id,
                });
            } else if (!this.state.haveShopper && this.props.data.haveShopper) {
                // Delete shopper
                return new ManagementUserApi().managementDeleteUser(this.props.data.shopperId)
            }
            return null;
        }).then(() => {
            if (this.props.onSuccess) {
                this.props.onSuccess()
            }
            notification.success({message: `${data.name} was successfully modified`})
        }).catch((err) => {
            notification.warning({
                message: `Cannot modify  ${this.props.data.name}`,
                description: `${err}`,
                duration: 0
            });
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (err) {
                return;
            }

            console.log('Received values of form: ', values);
            this[this.props.formType](values)
        });
    }

    componentDidMount() {
        this.setState({haveShopper: this.props.data.haveShopper})
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {name, haveShopper} = this.props.data;
        let shopperInitialValue = [];
        if(haveShopper){
            shopperInitialValue.push('shopper');
        }
        return <Drawer
            title={this.props.title}
            width={720}
            onClose={this.props.onClose}
            visible={this.props.isOpen}
        >
            <Form layout="vertical" onSubmit={this.handleSubmit}>
                {/* Class */}
                <Form.Item label="Class" hasFeedback colon={false}>
                    {getFieldDecorator('name', {rules: [{required: true}], initialValue: name})(
                        <Input type="text"/>
                    )}
                </Form.Item>
                {/* Shopper enabled */}
                <Form.Item label="Shopper enabled" colon={false}>
                    {getFieldDecorator('haveShopper', {initialValue: shopperInitialValue})(
                        <Checkbox.Group onChange={(v) => {
                            this.setState({haveShopper: v.length > 0})
                        }}>
                            <Checkbox value="shopper"/>
                        </Checkbox.Group>
                    )}
                </Form.Item>
                {/* Shopper password */}
                {this.state.haveShopper &&
                <Form.Item label="Shopper Password" hasFeedback colon={false}>
                    {getFieldDecorator('shopperPassword', {
                        rules: [{required: haveShopper ? false : true}],
                    })(
                        <Input type="password"/>
                    )}
                </Form.Item>
                }
                <Form.Item>
                    <Button type="primary" htmlType="submit">Submit</Button>
                </Form.Item>
            </Form>
        </Drawer>
    }
}

export default CUForm;