import {createStore, applyMiddleware, compose} from 'redux'
import createSagaMiddleware from 'redux-saga'
import createRootReducer from './reducer'
import saga from './saga'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'

export const history = createBrowserHistory()

const sagaMiddleware = createSagaMiddleware();

const store = createStore(createRootReducer(history), compose(applyMiddleware(
    routerMiddleware(history),
    sagaMiddleware)));

sagaMiddleware.run(saga);



export default store