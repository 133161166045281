import React, {Component} from "react";
import {Form, Checkbox, DatePicker} from 'antd';
import './FilterBlock.scss'
import _ from 'lodash'

class FilterBlock extends Component {

    render() {
        let filterComponents = [];
        if (Array.isArray(this.props.columns)) {
            this.props.columns.forEach((v) => {
                if (!v.filter || !v.filter.type) {
                    return
                }

                filterComponents.push(<v.filter.type key={v.dataIndex}
                                                     title={v.filter.title ? v.filter.title : v.title}
                                                     defaultValue={v.filter.defaultValue}
                                                     options={v.filter.options}
                                                     onChange={v.filter.onChange}
                />)
                //filterComponents = React.createElement(v.filter.type, {key: this.props.columns.dataIndex}, null)

            })
        }

        return <Form layout="vertical">
            {filterComponents}
        </Form>
    }

}

class CheckboxListBlock extends Component {
    state = {checkedList: [], options: {}, propsOptions: []};

    static getDerivedStateFromProps(props, currentState) {
        if (_.isEqual(currentState.propsOptions, props.options)) {
            return null
        }

        return {
            options: Array.isArray(props.options) ? _.keyBy(props.options) : {},
            propsOptions: props.options,
            checkedList: props.defaultValue ? props.defaultValue : [],
        }
    }

    render() {
        const {title, onChange, defaultValue} = this.props;
        const {checkedList, options} = this.state;

        return <Form.Item className="filter-checkbox" label={title} style={{marginBottom: 5}}>
            <Checkbox.Group
                options={Object.keys(options)} value={checkedList}
                onChange={
                    (v) => {
                        this.setState({checkedList: v});
                        if (onChange) {
                            onChange(_(options).pick(v).valuesIn().value());
                        }
                    }
                }/>
        </Form.Item>
    }
}

const MonthPickerBlock = (props) => {
    return <Form.Item label={props.title} style={{marginBottom: 5}}>
        <DatePicker.MonthPicker onChange={props.onChange}/>
    </Form.Item>
}


var type = {
    Checkbox: CheckboxListBlock,
    MonthPicker: MonthPickerBlock,
    Text: CheckboxListBlock,
};

export default FilterBlock;
export {type};
