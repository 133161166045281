import {actionType} from "~/src/redux/action";

export default function (state = {isLoggingIn: false, error: null}, {type, isLoggingIn, error}) {

    switch (type) {
        case actionType.CHANGE_LOGIN_PROCESSING_STATE:
            return {
                ...state,
                ...{isLoggingIn: isLoggingIn, error: error !== undefined ? error: state.error}
            };
        case actionType.LOGIN_ERROR:
            return {
                ...state,
                ...{error: error}
            };
        default:
            return state
    }
}
